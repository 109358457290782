import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Animation from '../../components/Animation';
import Button from '../../components/Button';
import Highlight from '../../components/Highlight';
import Typography from '../../components/Typography';
import { setShowDescription, setStarted } from '../../store/actions/items';
import Analytics from '../../utils/Analytics';
import ChairSVG from '../../components/images/ChairSvg';
import CouchSVG from '../../components/images/CouchSvg';
import LaptopSVG from '../../components/images/LaptopSvg';
import LogoAltSVG from '../../components/images/LogoAltSvg';
import LogoSVG from '../../components/images/LogoSvg';
import PhoneSVG from '../../components/images/PhoneSvg';
import RingSVG from '../../components/images/RingSvg';
import StateLogoSVG from '../../components/images/StateLogoSvg';
import WashingMachineSVG from '../../components/images/WashingMachineSvg';

const { Title, Text } = Typography;

/**
 * @param loading
 * @param START
 * @param showDescription
 * @param SHOW_DESCRIPTION
 * @returns {*}
 * @constructor
 */
const Splash = ({
  loading,
  setStarted: START,
  showDescription,
  setShowDescription: SHOW_DESCRIPTION,
}) => {
  const handleShowDescriptionOrStart = () => {
    window.scrollTo(0, 0);

    /**
     * If the user has already seen the
     * description page then just start the tool.
     */
    const TOKEN = 'on-boarded';
    const HAS_BEEN_ON_BOARDED = localStorage.getItem(TOKEN) === 'true';
    if (HAS_BEEN_ON_BOARDED) {
      Analytics.event({ category: 'About', action: 'Get_Started' });

      START();
    }

    localStorage.setItem(TOKEN, 'true');
    SHOW_DESCRIPTION();

    Analytics.pageView({ title: 'About' });
    Analytics.event({ category: 'Home', action: 'Lets_Go' });
  };

  const renderCanvas = () => (
    <div className="l-splash-page__canvas">
      <div className="l-splash-page__canvas-item l-splash-page__canvas-item--primary">
        <RingSVG alt='ring' width={82} height={110} />
      </div>
      <div className="l-splash-page__canvas-item l-splash-page__canvas-item--secondary">
      <LaptopSVG alt='laptop' width={142} height={118}/>
      </div>
      <div className="l-splash-page__canvas-item l-splash-page__canvas-item--tertiary">
      <PhoneSVG alt='phone' width={105} height={127}/>
      </div>
      <div className="l-splash-page__canvas-item l-splash-page__canvas-item--quinary">
      <WashingMachineSVG alt='washingMachine' width={100} height={105}/>
      </div>
      <div className="l-splash-page__canvas-item l-splash-page__canvas-item--senary">
      <CouchSVG alt='couch' width={100} height={154}/>
      </div>
      <div className="l-splash-page__canvas-item l-splash-page__canvas-item--septenary">
      <ChairSVG alt='chair' width={100} height={92}/>
      </div>
    </div>
  );

  return (
    <div className={`l-splash-page ${showDescription ? 'l-splash-page--scrollable' : ''}`}>

      {showDescription ? (
        <div className="u-d--none u-d--md-block">{renderCanvas()}</div>
      ) : (
        <>{renderCanvas()}</>
      )}
      <div className="l-splash-page__content">
        <div className="l-splash-page__content-header">
        <StateLogoSVG alt ='state' className="l-splash-page__state-logo" width={113} height={49} />
        </div>
        <Animation
          delay={200}
          variant="fadeInUp"
          className="l-splash-page__content-content"
          key={`content-${showDescription}`}
        >
          {showDescription ? (
            <div className="l-splash-page__logo-alt">
              <LogoAltSVG alt='logoalt' className="l-splash-page__logo-alt-item"/>
            </div>
          ) : (
            <div className="l-splash-page__logo">
              <LogoSVG alt='Logosvg' className="l-splash-page__logo-item" width={100} height={112}/>
            </div>
          )}
        </Animation>
        <div className="l-splash-page__content-footer" key={`footer-${showDescription}`}>
          {showDescription ? (
            <Animation variant="fadeInUp" delay={400}>
              <div className="c-content">
                <Text size="large">
                  Most of us have got a lot more stuff than we think. And like most people you’re
                  probably too busy for maths. So, I’m here to help you get an idea of just how much
                  stuff you’ve got!
                </Text>
                <Highlight size="medium">Is your stuff worth more than you think?</Highlight>
              </div>
              <div className="u-d--none u-d--md-block">
                <Button
                  id="SPLASH_PAGE_GET_STARTED_DESKTOP"
                  variant="primary-alt"
                  size="large"
                  bordered
                  onClick={() => START()}
                >
                  {loading ? `Loading...` : `Get started`}
                </Button>
              </div>
              <div className="u-d--md-none">
                <Button
                  id="SPLASH_PAGE_GET_STARTED_MOBILE"
                  variant="primary-alt"
                  size="wide"
                  bordered
                  onClick={() => START()}
                >
                  {loading ? `Loading...` : `Get started`}
                </Button>
              </div>
            </Animation>
          ) : (
            <>
              <Animation delay={400} variant="fadeInUp">
                <Title>
                  {/* eslint-ignore-next-line */}
                  {`Find out what your `}
                  <br className="u-d--md-none" />
                  {/* eslint-ignore-next-line */}
                  {`stuff's worth`}
                </Title>
              </Animation>
              <div className="u-d--none u-d--md-block">
                <Animation delay={600} variant="fadeInUp">
                  <Button
                    id="SPLASH_PAGE_START_DESKTOP"
                    variant="primary-alt"
                    size="large"
                    bordered
                    onClick={() => handleShowDescriptionOrStart()}
                  >
                    {loading ? `Loading...` : `Let's go`}
                  </Button>
                </Animation>
              </div>
              <div className="u-d--md-none">
                <Animation delay={600} variant="fadeInUp">
                  <Button
                    id="SPLASH_PAGE_START_MOBILE"
                    variant="primary-alt"
                    size="wide"
                    bordered
                    onClick={() => handleShowDescriptionOrStart()}
                  >
                    {loading ? `Loading...` : `Let's go`}
                  </Button>
                </Animation>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Splash.defaultProps = {
  loading: true,
  showDescription: false,
};

Splash.propTypes = {
  loading: PropTypes.bool,
  setStarted: PropTypes.func.isRequired,
  setShowDescription: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    loading: state.items.loading,
    showDescription: state.items.showDescription,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStarted: () => dispatch(setStarted()),
  setShowDescription: () => dispatch(setShowDescription()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
