import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param children
 * @param className
 * @param size
 * @param disabled
 * @param variant
 * @param props
 * @returns {*}
 * @constructor
 */
const TextButton = ({ children, className, size, disabled, variant, ...props }) => {
  const CLASS_NAMES = ClassNames('c-text-button', className, {
    'is-disabled': disabled,
    [`c-text-button--${size}`]: size,
    [`c-text-button--${variant}`]: variant,
  });

  return (
    <button {...props} disabled={disabled} className={CLASS_NAMES} type="button">
      <span className="c-text-button__content">{children}</span>
    </button>
  );
};

TextButton.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  size: null,
  variant: undefined,
};

TextButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['large']),
  variant: PropTypes.oneOf(['base']),
};

export default TextButton;
