import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';

/**
 * @param children
 * @param className
 * @param delay
 * @param variant
 * @returns {*}
 * @constructor
 */
const Animation = ({ children, className, delay, variant }) => {
  let props = null;

  switch (variant) {
    case 'fadeInUp':
      // eslint-disable-next-line react-hooks/rules-of-hooks
      props = useSpring({
        opacity: 1,
        transform: 'translateY(0)',
        from: { opacity: 0, transform: 'translateY(10%)' },
        delay,
      });
      break;
    default:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      props = useSpring({ opacity: 1, from: { opacity: 0 }, delay });
  }

  return (
    <animated.div style={props} className={className}>
      {children}
    </animated.div>
  );
};

Animation.defaultProps = {
  children: null,
  className: undefined,
  delay: 0,
  variant: 'fadeIn',
};

Animation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  delay: PropTypes.number,
  variant: PropTypes.oneOf(['fadeIn', 'fadeInUp', 'fadeInLeft', 'fadeInRight']),
};

export default Animation;
