import React from 'react';

/**
 * @returns {*}
 * @constructor
 */
const PlaceholderIcon = () => {
  return (
    <svg viewBox="0 0 1600 1028">
      <circle
        cx={712.84}
        cy={152.43}
        r={65.83}
        fill="#fff"
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={19}
      />
      <circle cx={721.47} cy={141.99} r={13.13} stroke="#000" strokeMiterlimit={10} />
      <path
        d="M1100.32 380.4c-7 94.53-62.62 141.52-157.27 177.43-43.57 16.19-59.56 30.61-68 56.27a17.53 17.53 0 01-17.94 12l-208.89-15.51A17.56 17.56 0 01632 592.16c5-95.88 54.55-136.55 156.66-171.49 36.71-12.61 42.09-16.3 42.7-24.43.75-10.17-9-16-34.45-17.89-60.67-4.5-120.65 4.85-195.75 38.73a23.17 23.17 0 01-30.41-11l-69.39-143.89a23.19 23.19 0 0111.24-31.14c109.81-50.32 206.55-63.9 326.9-55 175.86 13.05 270.09 79.32 260.82 204.35zM873.07 801.28a122.35 122.35 0 11-113-131.06 122.35 122.35 0 01113 131.06z"
        fill="#ff8217"
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={19}
      />
      <circle
        cx={827.25}
        cy={152.43}
        r={65.83}
        fill="#fff"
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={19}
      />
      <circle cx={835.88} cy={149.92} r={13.13} stroke="#000" strokeMiterlimit={10} />
      <path
        d="M858.43 282.68a44.11 44.11 0 01-62.34 2.08"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={19}
      />
    </svg>
  );
};

export default PlaceholderIcon;
