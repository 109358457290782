import React from 'react'


const ChevronUpSvg = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7.41" width={width} height={height} className={className}>
        <path fill="currentColor" d="M1.41 7.41L0 6l6-6 6 6-1.41 1.41L6 2.81z"/>
    </svg>

  )
}

export default ChevronUpSvg;