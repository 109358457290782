import React from 'react'


const StateLogoSvg = ({width, height, className, onClick}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113 49" width={width} height={height} className={className} onClick={onClick}>
        <path d="M56.66 0a213.48 213.48 0 0155.46 7.18v34.35c-5.7 1.77-27.62 7.29-55.46 7.29S6.47 43.3.88 41.53V7.29A215.33 215.33 0 0156.66 0z" fill="#004b93" fillRule="evenodd"/>
        <path d="M87.9 19.4a.91.91 0 00-.89-1h-1.45v-2.47h15.36v5.3h-2.41v-1c-.08-2.13-2.62-1.84-2.62-1.84h-3.42v4.71h1.24c.88.08 1.18-.94 1.18-.94v-.85h2v6.09h-2v-.76a1.05 1.05 0 00-1.18-1.17h-1.12v5.07H96c2.34-.13 2.51-1.91 2.51-1.91v-.89H101v5.15H85.67v-2.35H87a.86.86 0 00.89-1zM74.34 32.87h4.48v-2.32H77.5a.91.91 0 01-.86-.94V18.42h1.18a2.22 2.22 0 012.36 2.1v.86h2.41v-5.44H66v5.46h2.4c0-.37.06-.6.07-.9a2.18 2.18 0 012.34-2.08h1.18v11.19a.91.91 0 01-.86.94h-1.27v2.32zM55 19.38l-1.86 5.75h3.75zm1.76 13.49v-2.34h.86s1.12 0 .75-1l-.65-2h-5.4l-.63 2a.71.71 0 00.76 1h.85v2.34H46v-2.34h.87s1.25 0 1.49-1l4.72-13.6h6l4.34 13.6a1.57 1.57 0 001.37 1h1v2.34zm-19.14 0h4.48v-2.32h-1.3a.91.91 0 01-.86-.94V18.42h1.18a2.21 2.21 0 012.35 2.1v.86h2.42v-5.44H29.34v5.46h2.39c0-.37.06-.6.07-.9a2.2 2.2 0 012.35-2.08h1.18v11.19a.91.91 0 01-.87.94h-1.3v2.32zM20.54 15.72a13.94 13.94 0 00-2.5 0c-3.41.37-5.41 2.21-5.65 4.48a4.08 4.08 0 000 1c.38 3 3.64 4 4.91 4.44 1.94.66 5.17 1.58 5 3.43-.09 1.14-1.44 1.64-2.86 1.71a5.47 5.47 0 01-3-.63 5.91 5.91 0 01-2.32-3.39H12v6.07h2.31l.29-1a8 8 0 003.94 1.28 19.42 19.42 0 002 0c4.83-.39 6.29-3 6.53-4.92a3.93 3.93 0 000-1c-.29-2.36-1.58-3.45-4.45-4.61-2.37-1-5.7-1.38-5.74-3.07C17 18.4 18.57 18 19.44 18A4.26 4.26 0 0124 21.28h2.26S26 16.19 26 15.9h-2.17c-.15.32-.12.29-.29.69a7.32 7.32 0 00-3-.87z" fill="#fff"/>
    </svg>
  )
}

export default StateLogoSvg;