import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/index.scss';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

import App from './App'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER_ID
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App/>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
