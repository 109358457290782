import React from 'react'


const Error404Svg = ({width, height, className}) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 297 205" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M46.897 184.815H83.737V201.495H100.057V184.815H112.897V170.655H100.057V123.615H79.657L46.897 167.535V184.815ZM83.737 170.655H61.537L83.737 140.895V170.655ZM132.539 162.615C132.539 154.455 133.739 147.495 136.739 142.815C139.139 138.975 142.859 136.455 148.499 136.455C154.259 136.455 157.739 138.975 160.139 142.815C163.259 147.495 164.459 154.455 164.459 162.615C164.459 170.775 163.259 177.735 160.139 182.415C157.739 186.255 154.259 188.775 148.499 188.775C142.859 188.775 139.139 186.255 136.739 182.415C133.739 177.735 132.539 170.775 132.539 162.615ZM115.979 162.615C115.979 172.575 117.539 182.295 122.819 190.335C127.979 198.015 136.379 203.415 148.499 203.415C160.619 203.415 169.019 198.015 174.059 190.335C179.339 182.295 181.019 172.575 181.019 162.615C181.019 152.655 179.339 142.935 174.059 134.895C169.019 127.215 160.619 121.815 148.499 121.815C136.379 121.815 127.979 127.215 122.819 134.895C117.539 142.935 115.979 152.655 115.979 162.615ZM186.181 184.815H223.021V201.495H239.341V184.815H252.181V170.655H239.341V123.615H218.941L186.181 167.535V184.815ZM223.021 170.655H200.821L223.021 140.895V170.655Z" fill="white"/>
    <path d="M58.646 84.6914C56.7219 84.6914 55.6397 83.8697 55.0585 83.1882C53.6154 81.4847 53.9762 79.0596 54.0163 78.779L54.3369 73.9088C54.4171 72.7864 54.4171 71.644 54.377 70.4816C54.3169 69.1989 54.3169 68.2369 54.3169 67.816C54.2969 62.605 55.8802 58.4363 59.0268 55.3498C64.1776 50.3193 71.7134 50.0587 73.1765 50.0587H73.3969C75.1807 50.1188 76.6037 51.602 76.5636 53.3857C76.5235 55.1294 75.0604 56.5523 73.3168 56.5523C72.5351 56.5523 66.9634 56.6726 63.5563 59.9996C61.7124 61.8034 60.7905 64.3888 60.8105 67.6757C60.8105 68.9383 60.9909 72.0048 61.1713 72.586C61.3917 73.067 62.9149 74.8507 64.1575 76.1735C65.0394 77.2357 64.9191 78.6988 63.9772 79.5606C63.5563 79.9414 63.0152 80.1619 62.454 80.1819C62.5141 81.2441 62.3738 82.3665 61.7525 83.2484C61.2915 83.9097 60.3896 84.7114 58.6861 84.7114L58.646 84.6914Z" fill="#E4E4E4"/>
    <path d="M73.1566 51.5018C73.2568 51.5018 73.317 51.5018 73.357 51.5018C74.3592 51.5218 75.1408 52.3636 75.1208 53.3457C75.1007 54.3277 74.279 55.1094 73.317 55.1094H73.1967C72.4952 55.1094 66.3624 55.2096 62.5343 58.9775C60.4099 61.0819 59.3276 64.008 59.3477 67.6757C59.3477 68.6778 59.508 72.4457 59.8086 73.1071C60.3097 74.2094 63.0755 77.1556 63.0755 77.1556C63.3962 77.5565 63.3561 78.1377 62.9753 78.4784C62.7949 78.6387 62.5744 78.7189 62.3339 78.7189C62.0934 78.7189 61.873 78.6387 61.6926 78.4984L60.3698 77.4763C60.3698 77.4763 62.5544 83.2484 58.6462 83.2484C54.7179 83.2484 55.4395 79.0195 55.4395 79.0195L55.7601 73.989C55.8403 72.8065 55.8403 71.604 55.8002 70.4015C55.7401 69.0186 55.7401 67.9964 55.7401 67.6757C55.72 62.9659 57.1631 59.1579 60.0291 56.352C64.7991 51.6822 71.8739 51.4818 73.1566 51.4818M73.1566 48.5957C71.5934 48.5957 63.5565 48.8763 58.0048 54.3077C54.5576 57.6748 52.834 62.1842 52.854 67.6958C52.854 68.2169 52.854 69.219 52.9142 70.5418C52.9542 71.6441 52.9542 72.7464 52.8741 73.8086L52.5534 78.7189C52.4331 79.5406 52.2528 82.1261 53.9363 84.1303C54.7179 85.0522 56.161 86.1345 58.6261 86.1345C60.45 86.1345 61.9531 85.3929 62.8951 84.0501C63.4763 83.2083 63.7369 82.2663 63.8371 81.3043C64.2379 81.144 64.5987 80.9035 64.9193 80.6229C66.4225 79.24 66.6029 76.9352 65.3202 75.3318C65.2801 75.2717 65.24 75.2316 65.1799 75.1715C64.0575 73.9689 62.8951 72.586 62.4943 72.0048C62.354 71.1631 62.2337 68.778 62.2137 67.6557C62.2137 64.7897 62.9753 62.545 64.5385 61.0218C67.5849 58.0155 72.9562 57.9754 73.1767 57.9754H73.2969C75.8222 57.9754 77.9066 55.9311 77.9868 53.4058C78.0268 52.1632 77.5659 50.9607 76.7041 50.0588C75.8423 49.1569 74.6798 48.6358 73.4172 48.5957H73.1366H73.1566Z" fill="#231F20"/>
    <path d="M171.643 50.4832C169.84 50.5232 168.437 52.0264 168.477 53.8101C168.517 55.5538 169.98 56.9768 171.723 56.9768C172.505 56.9768 178.077 57.097 181.484 60.424C183.328 62.2278 184.25 64.8132 184.23 68.1001C184.23 69.5031 184.049 72.4693 183.869 73.0104C183.649 73.4914 182.125 75.2752 180.883 76.598C180.021 77.6802 180.121 79.1232 181.063 79.9851C181.484 80.3659 182.025 80.5863 182.586 80.6064C182.526 81.6686 182.666 82.7909 183.288 83.6728C183.749 84.3342 184.651 85.1359 186.354 85.1359C188.278 85.1359 189.36 84.3141 189.942 83.6327C191.385 81.9291 191.024 79.484 190.984 79.2235L190.683 74.3532C190.603 73.2309 190.603 72.0885 190.643 70.9261C190.703 69.6434 190.703 68.6813 190.703 68.2605C190.723 63.0495 189.14 58.8808 185.993 55.7943C180.843 50.7637 173.307 50.5032 171.844 50.5032H171.623L171.643 50.4832Z" fill="#E4E4E4"/>
    <path d="M186.374 83.6728C190.302 83.6728 189.581 79.4439 189.581 79.4439L189.26 74.4134C189.18 73.2309 189.18 72.0284 189.22 70.8258C189.28 69.4429 189.28 68.4208 189.28 68.1001C189.3 63.3902 187.857 59.5823 184.991 56.7764C180.221 52.1066 173.146 51.9062 171.864 51.9062C171.764 51.9062 171.683 51.9062 171.663 51.9062C170.661 51.9262 169.88 52.768 169.9 53.75C169.92 54.7321 170.741 55.5137 171.703 55.5137H171.824C172.525 55.5137 178.658 55.6139 182.486 59.3818C184.611 61.4863 185.693 64.4124 185.673 68.0801C185.673 69.0822 185.512 72.8501 185.212 73.5115C184.711 74.6138 181.945 77.56 181.945 77.56C181.624 77.9608 181.664 78.542 182.045 78.8828C182.226 79.0431 182.446 79.1233 182.686 79.1233C182.927 79.1233 183.147 79.0431 183.328 78.9028L184.651 77.8807C184.651 77.8807 182.466 83.6528 186.374 83.6528M186.374 86.5388C184.55 86.5388 183.047 85.7973 182.125 84.4544C181.544 83.6127 181.284 82.6707 181.183 81.7087C180.783 81.5483 180.422 81.3078 180.101 81.0273C178.598 79.6444 178.418 77.3395 179.7 75.7362C179.74 75.676 179.78 75.6359 179.841 75.5758C180.963 74.3733 182.125 72.9904 182.526 72.4092C182.666 71.5674 182.787 69.1824 182.807 68.06C182.807 65.194 182.045 62.9493 180.482 61.4261C177.435 58.4198 172.064 58.3797 171.844 58.3797H171.723C169.198 58.3797 167.114 56.3354 167.034 53.8101C166.994 52.5675 167.455 51.365 168.316 50.4631C169.178 49.5612 170.341 49.0401 171.603 49.0001H171.884C173.447 49.0001 181.484 49.2806 187.036 54.692C190.483 58.0591 192.206 62.5685 192.186 68.0801C192.186 68.6012 192.186 69.6033 192.126 70.9261C192.086 72.0284 192.086 73.1307 192.166 74.1929L192.487 79.1032C192.607 79.9249 192.788 82.5104 191.104 84.5146C190.323 85.4365 188.879 86.5188 186.414 86.5188L186.374 86.5388Z" fill="#231F20"/>
    <path d="M151.14 98.5204C150.459 96.8769 149.397 95.3938 148.014 94.1913C145.909 92.3474 143.264 91.4255 140.759 91.666C138.454 91.8865 129.234 92.5278 129.154 92.5278L129.615 99.0415C129.996 99.0214 138.995 98.3801 141.38 98.1596C142.101 98.0995 142.983 98.4402 143.725 99.1016C144.767 100.024 145.428 101.366 145.489 102.689C145.669 106.898 145.569 113.452 145.569 113.472L152.102 113.712C152.102 113.572 152.223 106.898 152.022 102.409C151.962 101.066 151.662 99.743 151.14 98.5003V98.5204Z" fill="#E4E4E4" stroke="#231F20" strokeWidth="2.88606" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M168.657 38.1137L136.71 38.2941C136.189 38.2941 135.748 37.8732 135.748 37.3521L135.568 5.40511C135.568 4.88402 135.127 4.46313 134.606 4.46313L109.112 4.60343C108.591 4.60343 108.17 5.04435 108.17 5.56545L108.351 37.5125C108.351 38.0336 107.93 38.4745 107.409 38.4745L75.4615 38.6549C74.9404 38.6549 74.5195 39.0958 74.5195 39.6169L74.6598 65.1104C74.6598 65.6315 75.1008 66.0524 75.6218 66.0524L107.569 65.872C108.09 65.872 108.531 66.2929 108.531 66.814L108.711 98.761C108.711 99.2821 109.152 99.703 109.673 99.703L135.167 99.5627C135.688 99.5627 136.109 99.1217 136.109 98.6007L135.928 66.6536C135.928 66.1325 136.349 65.6916 136.87 65.6916L168.817 65.5112C169.338 65.5112 169.759 65.0703 169.759 64.5492L169.619 39.0557C169.619 38.5346 169.178 38.1137 168.657 38.1137Z" fill="#FF8217" stroke="#231F20" strokeWidth="2.88606" strokeMiterlimit="10"/>
    <path d="M118.221 99.9861L111.227 99.9666L111.128 135.1L118.123 135.12L118.221 99.9861Z" fill="#E4E4E4" stroke="#231F20" strokeWidth="2.88606" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M105.524 132.479L118.21 132.514C118.912 132.516 119.471 133.079 119.47 133.78L119.453 139.653C119.451 140.354 118.888 140.914 118.187 140.912L101.191 140.864C100.49 140.862 99.9303 140.3 99.9322 139.598L99.9366 138.035C99.9452 134.968 102.457 132.47 105.524 132.479Z" fill="#E82C41" stroke="#231F20" strokeWidth="2.88606" strokeMiterlimit="10"/>
    <path d="M161.995 121.988L145 122.035C144.298 122.037 143.735 121.477 143.733 120.776L143.717 114.904C143.715 114.202 144.275 113.639 144.976 113.638L157.663 113.602C160.729 113.594 163.241 116.092 163.25 119.158L163.254 120.721C163.256 121.423 162.697 121.986 161.995 121.988Z" fill="#E82C41" stroke="#231F20" strokeWidth="2.88606" strokeMiterlimit="10"/>
    <path d="M104.383 20.5568C106.908 19.2541 109.894 19.2541 112.4 20.5769L104.383 20.5568Z" fill="#F2F2F2"/>
    <path d="M104.383 20.5568C106.908 19.2541 109.894 19.2541 112.4 20.5769" stroke="#231F20" strokeWidth="2.88606" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M131.66 20.2561C134.185 18.9533 137.172 18.9533 139.677 20.2761L131.66 20.2561Z" fill="#F2F2F2"/>
    <path d="M131.66 20.2561C134.185 18.9533 137.172 18.9533 139.677 20.2761" stroke="#231F20" strokeWidth="2.88606" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M135.668 42.5028C139.376 42.5028 142.382 39.4968 142.382 35.7887C142.382 32.0806 139.376 29.0746 135.668 29.0746C131.96 29.0746 128.954 32.0806 128.954 35.7887C128.954 39.4968 131.96 42.5028 135.668 42.5028Z" fill="#F2F2F2" stroke="#231F20" strokeWidth="2.88606" strokeLinejoin="round"/>
    <path d="M135.668 38.755C137.307 38.755 138.635 37.4269 138.635 35.7887C138.635 34.1505 137.307 32.8225 135.668 32.8225C134.03 32.8225 132.702 34.1505 132.702 35.7887C132.702 37.4269 134.03 38.755 135.668 38.755Z" fill="#231F20"/>
    <path d="M108.391 42.4025C112.099 42.4025 115.105 39.3965 115.105 35.6885C115.105 31.9804 112.099 28.9744 108.391 28.9744C104.683 28.9744 101.677 31.9804 101.677 35.6885C101.677 39.3965 104.683 42.4025 108.391 42.4025Z" fill="#F2F2F2" stroke="#231F20" strokeWidth="2.88606" strokeLinejoin="round"/>
    <path d="M108.391 38.6547C110.029 38.6547 111.357 37.3267 111.357 35.6885C111.357 34.0503 110.029 32.7223 108.391 32.7223C106.753 32.7223 105.425 34.0503 105.425 35.6885C105.425 37.3267 106.753 38.6547 108.391 38.6547Z" fill="#231F20"/>
    <path d="M115.125 54.0118C119.655 50.6447 125.046 50.6648 129.535 54.0719" stroke="#231F20" strokeWidth="2.88606" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>



  )
}

export default Error404Svg;