import React from 'react'


const WashingMachineSVG = ({width, height, className}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 815.87 899.71" width={width} height={height} className={className}>
    <rect x="90.99" y="64.4" width="574.51" height="639.11" rx="23.36" transform="rotate(-13.95 378.245 383.97)" fill="#54585a"/>
    <path
        d="M193.54 767.89A32.91 32.91 0 01161.65 743L18.84 168a32.89 32.89 0 0124-39.81L555 1a32.86 32.86 0 0139.81 24l142.84 574.87a32.86 32.86 0 01-24 39.81L201.46 766.92a32.7 32.7 0 01-7.92.97zM47.39 146.66a13.86 13.86 0 00-10.11 16.79l142.81 574.92a13.87 13.87 0 0016.79 10.11L709.1 621.24a13.84 13.84 0 0010.11-16.79L576.4 29.53a13.86 13.86 0 00-16.79-10.11L47.39 146.66z"/>
    <circle cx="117.62" cy="182.65" r="29.55" transform="rotate(-79.1 117.623 182.646)" fill="#e4e5e6"/>
    <path d="M117.62 221.7a39.05 39.05 0 1139-39 39.1 39.1 0 01-39 39zm0-59.1a20 20 0 1020 20.05 20.07 20.07 0 00-20-20.05z"/>
    <path d="M89.856 176.455l55.125-6.173 2.115 18.882-55.125 6.173z"/>
    <rect x="72.31" y="183.94" width="671.24" height="639.11" rx="20.45" transform="rotate(-13.95 407.923 503.5)" fill="#e4e5e6"/>
    <path
        d="M174.17 899.71A30 30 0 01145.1 877L.89 296.41a30 30 0 0121.84-36.28l611.75-152A30 30 0 01670.77 130L815 710.58a30 30 0 01-21.84 36.29l-611.75 152a30 30 0 01-7.24.84zM27.31 278.57a10.94 10.94 0 00-8 13.26L163.54 872.4a11 11 0 0013.27 8l611.75-152a10.94 10.94 0 008-13.27L652.33 134.6a10.94 10.94 0 00-13.27-8l-611.75 152z"/>
    <rect x="175.3" y="132.7" width="53.55" height="58.64" rx="6.67" transform="rotate(-13.95 202.06 162.022)" fill="#fdeb00"/>
    <path d="M188 205a16.19 16.19 0 01-15.69-12.27l-10.92-44a16.19 16.19 0 0111.79-19.59l39-9.69a16.18 16.18 0 0119.59 11.79l10.92 44a16.18 16.18 0 01-11.8 19.59l-39 9.69a15.83 15.83 0 01-3.89.48zm-7.47-58l9.55 38.47 33.54-8.33-9.56-38.47zm-2.75.68z"/>
    <circle cx="407.91" cy="503.57" r="248.96" fill="#e4e4e4"/>
    <path d="M407.91 762a258.46 258.46 0 11182.76-75.7A256.78 256.78 0 01407.91 762zm0-497.92a239.44 239.44 0 10169.33 70.13 237.88 237.88 0 00-169.33-70.1z"/>
    <circle cx="407.91" cy="503.57" r="196.41" fill="#009fde"/>
    <path d="M407.91 709.48C294.38 709.48 202 617.11 202 503.57s92.38-205.91 205.91-205.91S613.82 390 613.82 503.57s-92.37 205.91-205.91 205.91zm0-392.82C304.85 316.66 221 400.5 221 503.57s83.85 186.91 186.91 186.91 186.91-83.85 186.91-186.91S511 316.66 407.91 316.66z"/>
    <ellipse cx="373.88" cy="519.06" rx="33.01" ry="33.97" fill="#e4e5e6"/>
    <path d="M373.88 562.53c-23.44 0-42.51-19.5-42.51-43.47s19.07-43.47 42.51-43.47 42.51 19.5 42.51 43.47-19.07 43.47-42.51 43.47zm0-67.94c-13 0-23.51 11-23.51 24.47s10.55 24.47 23.51 24.47 23.51-11 23.51-24.47-10.54-24.47-23.51-24.47z"/>
    <circle cx="373.84" cy="519.07" r="12.38"/>
    <ellipse cx="434.74" cy="544.68" rx="33.01" ry="33.97" fill="#e4e5e6"/>
    <path d="M434.74 588.15c-23.44 0-42.51-19.5-42.51-43.47s19.07-43.47 42.51-43.47 42.51 19.5 42.51 43.47-19.06 43.47-42.51 43.47zm0-67.94c-13 0-23.51 11-23.51 24.47s10.55 24.47 23.51 24.47 23.51-11 23.51-24.47-10.54-24.47-23.51-24.47z"/>
    <circle cx="434.7" cy="544.69" r="12.38"/>
    <path d="M532.09 544.36a9.5 9.5 0 01-8.5-5.26v-.05c-41.75-82.73-143-116.1-225.83-74.36a9.5 9.5 0 11-8.55-17c92.15-46.46 204.92-9.3 251.38 82.85l.22.47a9.49 9.49 0 01-4.45 12.31 9.34 9.34 0 01-4.27 1.04z"/>
    <path
        d="M296.93 480.56a9.15 9.15 0 01-1.27-.09 9.49 9.49 0 01-8.16-10.66 8.52 8.52 0 00-7.3-9.55 9.5 9.5 0 112.53-18.83 27.53 27.53 0 0123.6 30.88 9.5 9.5 0 01-9.4 8.25zM526.1 557.9a9.32 9.32 0 01-2.14-.25 9.49 9.49 0 01-7.12-11.39 27.54 27.54 0 0132.91-20.62 9.5 9.5 0 11-4.23 18.52 8.51 8.51 0 00-10.17 6.37 9.49 9.49 0 01-9.25 7.37z"/>
    </svg>


  )
}

export default WashingMachineSVG;