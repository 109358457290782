import { useEffect, useState } from 'react';

const useAlertBox = () => {
  const [open, setOpen] = useState(false);
  const [hasOpen, setHasOpen] = useState(false);
  let timer = () => {};

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Open the alert box
  const openAlertBox = () => {
    timer = setTimeout(() => {
      setOpen(true);
      setHasOpen(true);
    }, 150);
  };

  // Close the alert box
  const closeAlertBox = () => {
    setOpen(false);
  };

  return { open, hasOpen, openAlertBox, closeAlertBox };
};

export default useAlertBox;
