import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param className
 * @param steps
 * @param active
 * @param variant
 * @returns {*}
 * @constructor
 */
const Indicator = ({ className, steps, active, variant }) => {
  const CLASS_NAMES = ClassNames('c-indicator', className, {
    'c-indicator--primary': variant === 'primary',
    'c-indicator--secondary': variant === 'secondary',
  });

  const STEP_ARRAY = Array.from(Array(steps).keys());
  const STEPS = STEP_ARRAY.map((item) => (
    <li className={`c-indicator__item${item < active ? ' is-active' : ''}`} />
  ));

  return <ol className={CLASS_NAMES}>{STEPS}</ol>;
};

Indicator.defaultProps = {
  className: '',
  steps: 10,
  active: 2,
  variant: null,
};

Indicator.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.number,
  active: PropTypes.number,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default Indicator;
