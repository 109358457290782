import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Typography from './Typography';

/**
 * @param className
 * @param level
 * @param truncate
 * @param props
 * @returns {*}
 * @constructor
 */
const Title = ({ className, level, truncate, ...props }) => {
  const COMPONENT = `h${level}`;
  const CLASS_NAMES = ClassNames(
    'c-title',
    `c-title--${COMPONENT}`,
    {
      'c-title--truncate': truncate,
    },
    className
  );

  return <Typography {...props} className={CLASS_NAMES} component={COMPONENT} />;
};

Title.defaultProps = {
  className: '',
  children: null,
  level: 3,
  truncate: false,
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  // What level of heading the title will be.
  level: PropTypes.oneOf([1, 2, 3, 4]),
  truncate: PropTypes.bool,
};

export default Title;
