import Error404Svg from "../components/images/404Svg";
import StateLogoSvg from "../components/images/StateLogoSvg";
import Animation from '../components/Animation';


import Button from '../components/Button';

export default function PageNotFound() {
    return (
        <div className={`l-splash-page l-splash-page--scrollable`}>
            <div className="l-splash-page__content">
                <div className="l-splash-page__content-header">
                    <StateLogoSvg alt ='state' className="l-splash-page__state-logo" width={113} height={49} onClick={()=> document.location.href="/"}/>
                </div>
                <Animation
                    delay={200}
                    variant="fadeInUp"
                    className="l-splash-page__content-content">
                <div className="l-splash-page__logo">
                <Error404Svg width="297" height="205" className="l-splash-page__logo-item" /> 
                </div>
                </Animation>
                <Animation variant="fadeInUp" delay={400}>
              <div className="c-content">
                <h1>Oops, page not found.</h1>
                <p>We can’t find the page that you’re looking for.</p>
              </div>
              <div className="l-splash-page__content-footer" >
              <div className="u-d--none u-d--md-block">
                <Button
                  id="Go-Home"
                  variant="primary-alt"
                  size="large"
                  bordered
                  onClick={()=> document.location.href="/"}

                >
                    Go Home
                </Button>
              </div>

              <div className="u-d--md-none" href="/">
                <Button
                  id="SPLASH_PAGE_GET_STARTED_MOBILE"
                  variant="primary-alt"
                  size="wide"
                  bordered
                  onClick={()=> document.location.href="/"}
                >
                    Go Home
                </Button>
              </div>
              </div>
            </Animation>
            </div>
        </div>
    )
}