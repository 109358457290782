import React from 'react'


const LogoSvg = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1178.53 1319.63" width={width} height={height} className={className}>
        <path
            d="M134.72 537.93c-12.65 0-19.84-5.38-23.64-9.88-8.88-10.51-7.43-25.11-7-28.49l2.14-32.66a206.21 206.21 0 00.21-22.54c-.38-8.54-.41-14.95-.42-17.68V426c-.18-33.64 10.26-61 31.05-81.36 33.88-33.16 83.52-34.84 93.13-34.84h1.5a21.38 21.38 0 0120.82 21.94 21.51 21.51 0 01-21.35 20.8h-.74c-4.14 0-41.08.64-63.55 22.73-12.14 11.94-18.24 28.92-18.12 50.49.05 8.18 1.14 27.9 2.31 32.12 2 3.86 11.67 15.26 19.75 23.87.16.16.3.33.45.51a15.89 15.89 0 01-1.65 21.76 15.69 15.69 0 01-10.1 4c.47 8.3-1.06 15.07-4.59 20.19-3 4.4-9 9.65-20.22 9.65z"
            fill="#e4e4e4"/>
        <path
            d="M230.23 319.33h1.28a11.87 11.87 0 01-.31 23.74h-.74c-4.65 0-45 .71-70.21 25.46-14 13.8-21.09 33.08-21 57.31 0 6.56 1 31.47 3.07 35.83 3.34 7.19 21.57 26.61 21.57 26.61a6.37 6.37 0 01-.68 8.74 6.16 6.16 0 01-4.17 1.57 7 7 0 01-4.24-1.46l-8.74-6.77s14.44 38.07-11.36 38.07c-25.83 0-21.13-27.85-21.13-27.85l2.16-33.05a215.88 215.88 0 00.22-23.59c-.41-9.15-.4-15.8-.42-18-.17-31 9.31-56 28.19-74.51 31.37-30.71 78-32.13 86.49-32.13m0-19a167.31 167.31 0 00-39.31 5.12c-24.44 6.16-44.78 17.07-60.47 32.43-22.69 22.22-34.1 51.89-33.9 88.2v.64c0 2.79 0 9.34.43 18.07a194.75 194.75 0 01-.2 21.5l-2.11 32.29c-.74 5.47-1.93 22.49 9.14 35.6 5.1 6 14.61 13.24 30.89 13.25 12 0 21.94-4.89 28.07-13.76a37 37 0 006.15-18.06 24.94 24.94 0 007.11-4.54 25.39 25.39 0 002.63-34.77c-.29-.35-.58-.69-.89-1-7.37-7.85-15.1-17-17.69-20.86-.89-5.59-1.75-21.27-1.8-28.69-.1-18.9 5-33.59 15.28-43.66 20.11-19.77 55.4-20 56.89-20h.74a30.87 30.87 0 00.82-61.73h.01-1.73z"/>
        <path
            d="M880.25 332.48h-1.51a21.38 21.38 0 01-20.81-21.94 21.5 21.5 0 0121.35-20.8h.72c4.14 0 41.08-.64 63.55-22.73 12.14-11.93 18.23-28.92 18.11-50.48 0-8.19-1.13-27.91-2.3-32.13-2-3.85-11.68-15.26-19.76-23.87-.15-.16-.3-.33-.44-.5a15.88 15.88 0 011.65-21.76 15.65 15.65 0 0110.1-4.05c-.48-8.29 1.06-15.07 4.59-20.18 3-4.41 9-9.66 20.22-9.66 12.66 0 19.85 5.38 23.66 9.89 8.88 10.5 7.42 25.11 6.94 28.48l-2.13 32.66A201.34 201.34 0 001004 198c.38 8.54.41 15 .42 17.68v.65c.19 33.64-10.26 61-31 81.36-33.92 33.11-83.56 34.79-93.17 34.79z"
            fill="#e4e4e4"/>
        <path
            d="M975.74 113.88c25.83 0 21.14 27.85 21.14 27.85l-2.16 33.06a213.38 213.38 0 00-.23 23.59c.42 9.14.41 15.79.42 18 .17 31-9.31 56-28.19 74.51-31.37 30.71-78 32.13-86.48 32.13H879a11.87 11.87 0 01.31-23.74h.69c4.65 0 45-.71 70.21-25.45 14-13.81 21.09-33.09 20.95-57.32 0-6.55-1-31.47-3.06-35.82-3.35-7.2-21.57-26.62-21.57-26.62a6.37 6.37 0 01.67-8.74 6.16 6.16 0 014.17-1.57 6.94 6.94 0 014.24 1.46l8.74 6.77s-14.44-38.07 11.37-38.07m0-19c-12 0-21.92 4.89-28 13.76a37.06 37.06 0 00-6.16 18.06 25.08 25.08 0 00-7.11 4.54 25.39 25.39 0 00-2.64 34.72c.28.35.58.69.89 1 7.36 7.86 15.09 17 17.68 20.86.89 5.59 1.76 21.27 1.8 28.69.11 18.9-5 33.59-15.27 43.66-20.12 19.77-55.4 20-56.89 20h-.74a30.87 30.87 0 00-.82 61.73h1.79a167.42 167.42 0 0039.31-5.12c24.44-6.12 44.77-17.03 60.44-32.39 22.7-22.22 34.1-51.89 33.9-88.2v-.64c0-2.79 0-9.33-.43-18.07a194.57 194.57 0 01.2-21.49l2.11-32.3c.74-5.47 1.93-22.49-9.14-35.59-5.11-6-14.61-13.25-30.9-13.26z"/>
        <path d="M743.71 629a77.55 77.55 0 00-20.57-28.5c-13.91-12.13-31.33-18.2-47.8-16.66-15.14 1.42-75.82 5.66-76.44 5.7l3 43c2.52-.18 61.81-4.32 77.45-5.79 4.72-.44 10.51 1.9 15.49 6.25a34 34 0 0111.64 23.63c1.2 27.72.51 70.86.5 71.05l43 1.56c0-.88.76-44.92-.51-74.47a75.21 75.21 0 00-5.76-25.77z"
            fill="#e4e4e4" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="19"/>
        <path
            d="M859.08 231.18l-210.4 1.17a6.26 6.26 0 01-6.29-6.22l-1.18-210.4a6.27 6.27 0 00-6.3-6.23l-167.81.94a6.27 6.27 0 00-6.23 6.3l1.18 210.39a6.27 6.27 0 01-6.23 6.3l-210.4 1.18a6.26 6.26 0 00-6.23 6.29l.94 167.82a6.27 6.27 0 006.3 6.23l210.4-1.18a6.26 6.26 0 016.29 6.23l1.18 210.4a6.27 6.27 0 006.3 6.23l167.82-.94a6.26 6.26 0 006.22-6.3L643.47 419a6.26 6.26 0 016.22-6.3l210.4-1.17a6.27 6.27 0 006.23-6.3l-.94-167.82a6.26 6.26 0 00-6.3-6.23z"
            fill="#ff8217" stroke="#000" strokeMiterlimit="10" strokeWidth="19"/>
        <path fill="#e4e4e4" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="19" d="M480.865 638.47l46.02.128-.646 231.269-46.02-.128z"/>
        <path
            d="M443.287 852.504l83.48.233a8.33 8.33 0 018.307 8.354l-.108 38.68a8.33 8.33 0 01-8.353 8.306l-111.84-.312a8.33 8.33 0 01-8.307-8.353l.03-10.32a36.69 36.69 0 0136.791-36.588zM815.25 783.427l-111.84.312a8.33 8.33 0 01-8.354-8.306l-.108-38.68a8.33 8.33 0 018.307-8.354l83.48-.233a36.69 36.69 0 0136.792 36.588l.029 10.31a8.33 8.33 0 01-8.307 8.363z"
            fill="#e82c41" stroke="#000" strokeMiterlimit="10" strokeWidth="19"/>
        <path d="M435.89 115.55h0a56.91 56.91 0 0152.82.15h0M615.44 113.68h0a56.89 56.89 0 0152.81.15h0" fill="#f2f2f2" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="19"/>
        <circle cx="641.85" cy="215.92" r="44.26" fill="#f2f2f2" stroke="#000" strokeLinejoin="round" strokeWidth="19"/>
        <circle cx="641.85" cy="215.92" r="19.48"/>
        <circle cx="462.3" cy="215.24" r="44.26" fill="#f2f2f2" stroke="#000" strokeLinejoin="round" strokeWidth="19"/>
        <circle cx="462.3" cy="215.24" r="19.48"/>
        <path d="M601.44 319.77h0c-29.82 22.15-65.27 22-94.88-.44h0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="19"/>
        <path
            d="M208 877.32v331.16h-79.66V877.32H0v-75.06h336.32v75.06zM390.17 1046.34L459.5 1036c16-2.29 21.2-10.31 21.2-20 0-20.05-15.47-36.67-47.56-36.67-33.23 0-51.56 21.2-53.85 45.84l-67.61-14.33C316.26 966.7 356.94 918 432.57 918c89.38 0 122.61 50.41 122.61 107.14v138.65c0 14.9 1.72 35 3.44 44.69h-69.9c-1.72-7.45-2.87-22.92-2.87-33.81-14.32 22.35-41.25 41.83-83.07 41.83-60.16 0-96.83-40.68-96.83-84.8.05-50.42 37.24-78.49 84.22-85.36zm90.53 48.12v-12.6l-63.6 9.74c-19.48 2.86-35 13.75-35 35.52 0 16.61 12 32.66 36.67 32.66 32.13 0 61.93-15.47 61.93-65.32zM617.62 1208.48V793.67h76.2v414.81zM760.85 1208.48V793.67h76.21v414.81zM920.7 1319.63L987.16 1173 867.42 926.59h85.36l75.06 165 69.33-165h81.36l-177 393z"
            fill="#fff"/>
    </svg>
  )
}

export default LogoSvg;