import React from 'react'


const ChairSvg = ({width, height, className}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 639.47 851.61" width={width} height={height} className={className}>
    <path d="M310.23 354.06h19v460.56h-19z"/>
    <rect x="85.4" y="9.5" width="478.42" height="605.03" rx="78.46" fill="#d1d3d4"/>
    <path
        d="M485.36 624h-321.5a88.06 88.06 0 01-88-88V88a88.06 88.06 0 0188-88h321.5a88.06 88.06 0 0188 88v448.07a88.06 88.06 0 01-88 87.93zM163.86 19a69 69 0 00-69 69v448.07a69 69 0 0069 69h321.5a69 69 0 0069-69V88a69 69 0 00-69-69zM449.71 808.37a9.5 9.5 0 01-9.5-9.5v-79.94H190.55v79.94a9.5 9.5 0 01-19 0v-89.44a9.5 9.5 0 019.5-9.5h268.66a9.5 9.5 0 019.5 9.5v89.44a9.5 9.5 0 01-9.5 9.5z"/>
    <rect x="121.18" y="755.62" width="53.75" height="86.49" rx="12" fill="#54585a"/>
    <path d="M162.93 851.61h-29.75a21.52 21.52 0 01-21.5-21.5v-62.49a21.52 21.52 0 0121.5-21.5h29.75a21.52 21.52 0 0121.5 21.5v62.49a21.52 21.52 0 01-21.5 21.5zm-29.75-86.49a2.5 2.5 0 00-2.5 2.5v62.49a2.5 2.5 0 002.5 2.5h29.75a2.5 2.5 0 002.5-2.5v-62.49a2.5 2.5 0 00-2.5-2.5z"/>
    <rect x="458.22" y="755.62" width="53.75" height="86.49" rx="12" fill="#54585a"/>
    <path d="M500 851.61h-29.78a21.52 21.52 0 01-21.5-21.5v-62.49a21.52 21.52 0 0121.5-21.5H500a21.52 21.52 0 0121.5 21.5v62.49a21.52 21.52 0 01-21.5 21.5zm-29.75-86.49a2.5 2.5 0 00-2.5 2.5v62.49a2.5 2.5 0 002.5 2.5H500a2.5 2.5 0 002.5-2.5v-62.49a2.5 2.5 0 00-2.5-2.5z"/>
    <rect x="292.86" y="755.62" width="53.75" height="86.49" rx="12" fill="#54585a"/>
    <path d="M334.61 851.61h-29.75a21.52 21.52 0 01-21.5-21.5v-62.49a21.52 21.52 0 0121.5-21.5h29.75a21.52 21.52 0 0121.5 21.5v62.49a21.52 21.52 0 01-21.5 21.5zm-29.75-86.49a2.5 2.5 0 00-2.5 2.5v62.49a2.5 2.5 0 002.5 2.5h29.75a2.5 2.5 0 002.5-2.5v-62.49a2.5 2.5 0 00-2.5-2.5z"/>
    <rect x="9.5" y="558.83" width="620.47" height="88.94" rx="43.5" fill="#d1d3d4"/>
    <path d="M586.47 657.27H53a53.07 53.07 0 01-53-53v-1.94a53.07 53.07 0 0153-53h533.47a53.07 53.07 0 0153 53v1.94a53.07 53.07 0 01-53 53zM53 568.33a34 34 0 00-34 34v1.94a34 34 0 0034 34h533.47a34 34 0 0034-34v-1.94a34 34 0 00-34-34z"/>
    <path d="M314.87 331.16l-54.22 206.25a4.32 4.32 0 00.78 3.75l54.21 70.33a5.33 5.33 0 007.49.74 5.65 5.65 0 00.8-.82l52.4-70.94a4.31 4.31 0 00.73-3.64l-52.39-205.64c-1.21-4.74-8.56-4.76-9.8-.03z" fill="#ff2426"/>
    <path
        d="M319.76 622.93a14.8 14.8 0 01-11.46-5.41l-.18-.23L253.91 547a13.82 13.82 0 01-2.45-12l54.22-206.26a14.27 14.27 0 0114.08-10.63h.05a14.29 14.29 0 0114.07 10.72l52.39 205.64a13.75 13.75 0 01-2.35 11.7l-52.53 71.11a15 15 0 01-2.23 2.28 14.79 14.79 0 01-9.4 3.37zm-49.27-85.58l49.19 63.82 47.56-64.39-47.54-186.62zm-1.59-2.06l.06.08zm99.79-.46l-.05.06z"/>
    <path d="M346.73 332a26.93 26.93 0 11-26.89-27 26.93 26.93 0 0126.89 27z" fill="#ff2426"/>
    <path
        d="M319.8 368.36h-.09a36.43 36.43 0 01.09-72.86h.05a36.43 36.43 0 0136.38 36.5 36.47 36.47 0 01-36.43 36.36zm0-53.86a17.43 17.43 0 00-.05 34.86h.05A17.44 17.44 0 00337.23 332a17.43 17.43 0 00-17.4-17.47zM318.17 243a158.52 158.52 0 01-51.25-8.48 9.5 9.5 0 016.16-18 139.79 139.79 0 0090.17 0 9.5 9.5 0 016.16 18 158.55 158.55 0 01-51.24 8.48z"/>
    <ellipse cx="376.17" cy="133.19" rx="38.22" ry="38.76" fill="#fff"/>
    <path d="M376.17 181.45c-26.31 0-47.72-21.65-47.72-48.26s21.41-48.26 47.72-48.26 47.72 21.65 47.72 48.26-21.41 48.26-47.72 48.26zm0-77.52c-15.84 0-28.72 13.13-28.72 29.26s12.88 29.26 28.72 29.26 28.72-13.13 28.72-29.26-12.89-29.26-28.72-29.26z"/>
    <circle cx="375.68" cy="133.19" r="12.25"/>
    <ellipse cx="263.51" cy="133.19" rx="38.22" ry="38.76" fill="#fff"/>
    <path d="M263.51 181.45c-26.31 0-47.72-21.65-47.72-48.26s21.41-48.26 47.72-48.26 47.72 21.65 47.72 48.26-21.41 48.26-47.72 48.26zm0-77.52c-15.84 0-28.72 13.13-28.72 29.26s12.88 29.26 28.72 29.26 28.72-13.13 28.72-29.26-12.88-29.26-28.72-29.26z"/>
    <circle cx="263.02" cy="133.19" r="12.25"/>
    </svg>



  )
}

export default ChairSvg;