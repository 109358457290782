import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param children
 * @param className
 * @param size
 * @returns {*}
 * @constructor
 */
const Box = ({ children, className, size }) => {
  const CLASS_NAMES = ClassNames(
    'c-box',
    {
      [`c-box--${size}`]: size,
    },
    className
  );

  return <div className={CLASS_NAMES}>{children}</div>;
};

Box.defaultProps = {
  className: '',
  children: null,
  size: undefined,
};

Box.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small']),
};

export default Box;
