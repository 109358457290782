import _findIndex from 'lodash/findIndex';
import _hasIn from 'lodash/hasIn';
import data from '../../../data.json'

import {
  ADD_CUSTOM_ITEM,
  REMOVE_CUSTOM_ITEM,
  RESET_ITEMS,
  SET_ACTIVE_ITEM_ID,
  SET_COMPLETED,
  SET_CUSTOM_ITEM_VALUE,
  SET_ITEM_VALUE,
  SET_LOADING,
  SET_SHOW_DESCRIPTION,
  SET_STARTED,
  UPDATE_ITEMS,
  UPDATE_ITEMS_FAILURE,
  UPDATE_ITEMS_SUCCESS,
} from '../constants/items';

/**
 * Add an item
 *
 * @param state
 * @returns {{payload: *, type: string}}
 */
export const addCustomItem = (state) => ({
  type: ADD_CUSTOM_ITEM,
  payload: state,
});

/**
 * Remove an item from the custom array
 *
 * @param id
 * @returns {{payload: *, type: string}}
 */
export const removeCustomItem = (id) => ({
  type: REMOVE_CUSTOM_ITEM,
  payload: id,
});

/**
 * Set the active item id in the item array
 *
 * @param id
 * @returns {{payload: *, type: string}}
 */
export const setActiveItemId = (id) => ({
  type: SET_ACTIVE_ITEM_ID,
  payload: id,
});

/**
 * Set the state of the application to completed
 *
 * @returns {{type: string}}
 */
export const setCompleted = () => ({
  type: SET_COMPLETED,
});

/**
 * Set the state of the application to loading
 *
 * @returns {{type: string}}
 */
export const setLoading = () => ({
  type: SET_LOADING,
});

/**
 * Set the state of the application to started
 *
 * @returns {{type: string}}
 */
export const setStarted = () => ({
  type: SET_STARTED,
});

/**
 * Show the description
 *
 * @returns {{type: string}}
 */
export const setShowDescription = () => ({
  type: SET_SHOW_DESCRIPTION,
});

/**
 * Set the next item
 *
 * @returns {function(...[*]=)}
 */
export const setNextItemAsActive = () => {
  return (dispatch, getState) => {
    const {
      items: { activeItemId, items },
    } = getState();
    const ACTIVE_INDEX = _findIndex(items, { id: activeItemId });
    const NEXT_ITEM = items[ACTIVE_INDEX + 1];

    if (typeof NEXT_ITEM !== 'undefined' && NEXT_ITEM !== null) {
      dispatch(setActiveItemId(NEXT_ITEM.id));
    } else {
      // Reached the end of the items list
      dispatch(setActiveItemId(null));
      dispatch(setCompleted());
    }
  };
};

/**
 * Set the previous item
 *
 * @returns {function(...[*]=)}
 */
export const setPreviousItemAsActive = () => {
  return (dispatch, getState) => {
    const {
      items: { activeItemId, items },
    } = getState();
    const ACTIVE_INDEX = _findIndex(items, { id: activeItemId });
    const PREVIOUS_ITEM = items[ACTIVE_INDEX - 1];

    if (typeof PREVIOUS_ITEM !== 'undefined' && PREVIOUS_ITEM !== null) {
      dispatch(setActiveItemId(PREVIOUS_ITEM.id));
    } else {
      dispatch(setActiveItemId(null));
    }
  };
};

/**
 * Update a specific item in the items array
 *
 * @param state
 * @returns {{payload: *, type: string}}
 */
export const setItemValue = (state) => ({
  type: SET_ITEM_VALUE,
  payload: state,
});

/**
 * Update a specific item in the custom items array
 *
 * @param state
 * @returns {{payload: *, type: string}}
 */
export const setCustomItemValue = (state) => ({
  type: SET_CUSTOM_ITEM_VALUE,
  payload: state,
});

/**
 * Update the items array
 *
 * @param state
 * @return {{type: string, payload: *}}
 */
export const updateItems = (state) => ({
  type: UPDATE_ITEMS,
  payload: state,
});

/**
 * Failed to update items
 *
 * @param error
 * @returns {{payload: {error: *}, type: string}}
 */
export const updateItemsFailure = (error) => ({
  type: UPDATE_ITEMS_FAILURE,
  payload: {
    error,
  },
});

/**
 * @returns {{id: *, type: string}}
 */
export const updateItemsSuccess = () => ({
  type: UPDATE_ITEMS_SUCCESS,
});

/**
 * Retrieve items fro the window, or local storage.
 *
 * @returns {function(...[*]=)}
 */
export const getItems = () => {
  return (dispatch, getState) => {
    dispatch(setLoading());

    /**
     * Check if there are items loaded into
     * the user's local storage.
     */
    const {
      items: { items: EXISTING_ITEMS },
    } = getState();
    if (Array.isArray(EXISTING_ITEMS) && EXISTING_ITEMS.length) {
      dispatch(updateItemsSuccess());
      return;
    }
    window.data=data;
        if (_hasIn(window, ['data', 'items'])) {
      const ITEMS = window.data.items;

      /**
       * Check for items
       */
      if (!Array.isArray(ITEMS) || !ITEMS.length) {
        dispatch(updateItemsFailure('Empty array of items'));
        return;
      }

      const PALETTES = ['primary', 'secondary', 'tertiary'];
      let paletteIndex = 0;

      /**
       * Loop through items, and add additional keys
       * used in the application.
       */
      const UPDATED_ITEMS = ITEMS.map((item) => {
        const UPDATED_ITEM = { ...item };
        UPDATED_ITEM.value = null;

        // If there's no palette set on the item.
        if (typeof item.palette !== 'undefined' && item.palette === 'none') {
          /**
           * Loop through palettes for each item
           * and assign a palette to the item.
           */
          if (paletteIndex >= PALETTES.length) {
            paletteIndex = 0;
          }
          UPDATED_ITEM.palette = PALETTES[paletteIndex];
          paletteIndex += 1;
        }

        return UPDATED_ITEM;
      });

      dispatch(updateItems(UPDATED_ITEMS));
      dispatch(setActiveItemId(UPDATED_ITEMS[0].id));
      dispatch(updateItemsSuccess());
    } else {
      dispatch(updateItemsFailure('Missing window data'));
    }
  };
};

/**
 * Reset the items state to it's initial state.
 *
 * @returns {{type: string}}
 */
export const resetItems = () => ({
  type: RESET_ITEMS,
});

/**
 * @returns {{type: string}}
 */
export const playAgain = () => {
  return (dispatch) => {
    dispatch(resetItems());
    dispatch(getItems());
  };
};
