import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param block
 * @param bordered
 * @param children
 * @param className
 * @param disabled
 * @param size
 * @param type
 * @param variant
 * @param props
 * @returns {*}
 * @constructor
 */
const Button = ({
  block,
  bordered,
  children,
  className,
  disabled,
  size,
  type,
  variant,
  ...props
}) => {
  const CLASS_NAMES = ClassNames('c-button', className, {
    'is-bordered': bordered,
    'is-disabled': disabled,
    'c-button--primary': variant === 'primary',
    'c-button--primary-alt': variant === 'primary-alt',
    'c-button--secondary': variant === 'secondary',
    'c-button--tertiary': variant === 'tertiary',
    'c-button--small': size === 'small',
    'c-button--large': size === 'large',
    'c-button--wide': size === 'wide',
    'c-button--block': block,
  });

  return (
    // eslint-disable-next-line react/button-has-type
    <button {...props} disabled={disabled} className={CLASS_NAMES} type={type}>
      <span className="c-button__content">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  block: false,
  bordered: false,
  children: null,
  className: '',
  disabled: false,
  size: null,
  type: 'button',
  variant: null,
};

Button.propTypes = {
  block: PropTypes.bool,
  bordered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large', 'wide']),
  type: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'primary-alt', 'secondary', 'tertiary']),
};

export default Button;
