import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Checkout from '../../modules/Checkout';
import ItemForm from '../../modules/ItemForm';
import Splash from '../../modules/Splash';
import { getItems } from '../../store/actions/items';

/**
 * @returns {*}
 * @constructor
 */
class AppView extends React.Component {
  // Lifecycle
  // -------------------------------------------

  componentDidMount() {
    const { getItems: GET_ITEMS } = this.props;

    GET_ITEMS();
  }

  // Render
  // -------------------------------------------

  render() {
    const { completed, error, loading, started } = this.props;

    // Completed screen
    if (completed) {
      return <Checkout />;
    }

    // Loading screen
    if (loading) {
      return <div>Loading...</div>;
    }

    // Error screen
    if (error) {
      return <div>{error}</div>;
    }

    // Form screen
    if (started && !completed) {
      return <ItemForm />;
    }

    // Splash screen
    return <Splash loading={loading} />;
  }
}

AppView.defaultProps = {
  started: false,
  completed: false,
  error: null,
  loading: true,
};

AppView.propTypes = {
  started: PropTypes.bool,
  completed: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  getItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    started: state.items.started,
    completed: state.items.completed,
    loading: state.items.loading,
    error: state.items.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getItems: () => dispatch(getItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppView);
