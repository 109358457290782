/* eslint-disable react/jsx-one-expression-per-line */

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import AlertBox from '../../components/AlertBox';
import useAlertBox from '../../components/AlertBox/hooks/useAlertBox';
import Animation from '../../components/Animation';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Highlight from '../../components/Highlight';
import Indicator from '../../components/Indicator';
import TextButton from '../../components/TextButton';
import Typography from '../../components/Typography';
import { playAgain } from '../../store/actions/items';
import Analytics from '../../utils/Analytics';
import StateLogoSVG from '../../components/images/StateLogoSvg';
import ItemListForm from '../ItemListForm';
import ChevronDownSVG from '../../components/images/ChevronDownSvg';
import ChevronRightSVG from '../../components/images/ChevronRightSvg';
import ChevronUpSVG from '../../components/images/ChevronUpSvg';
import LogoSVG from '../../components/images/StateLogoSvg';

const { Title, Text } = Typography;

const PAGINATION_LENGTH = 3;

/**
 * @param customItems
 * @param items
 * @param PLAY_AGAIN
 * @returns {*}
 * @constructor
 */
const Checkout = ({ customItems, items, playAgain: PLAY_AGAIN }) => {
  const { open, hasOpen, openAlertBox, closeAlertBox } = useAlertBox();

  // Display max of 3 items.
  const ITEMS_WITH_VALUE = [...customItems, ...items].filter(
    (item) => typeof item.value !== 'undefined' && item.value !== null && item.value !== 0
  );

  // Lifecycle
  // -------------------------------------------

  // On mount
  useEffect(() => {
    Analytics.pageView({ title: 'Checkout' });
  }, []);

  // Handlers
  // -------------------------------------------

  /**
   * Scroll to a ref
   *
   * @param ref
   */
  const handleScrollToRef = (ref) => {
    window.requestAnimationFrame(() =>
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    );
  };
  const howIsTallyCalculated = useRef(null);
  const itemListRef = useRef(null);

  // Render
  // -------------------------------------------

  const [showAllItems, setShowAllItems] = useState(false);

  const renderTotalValue = () => {
    const COMBINED_ITEMS = [...customItems, ...items];
    const TOTAL = COMBINED_ITEMS.reduce((accumulator, item) => {
      if (typeof item.value !== 'undefined' && item.value !== null) {
        return accumulator + parseInt(item.value, 10);
      }

      return accumulator;
    }, 0);

    if (TOTAL >= 15000 && !hasOpen) openAlertBox();

    // Add commas to thousands.
    return TOTAL.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const renderContent = () => (
    <>
      <div className="u-d--lg-none u-mt--0">
        <LogoSVG alt='logo' width="140" />
      </div>
      <div className="u-mt--sm u-mt--lg-0">
        <Title level={2}>
          Hey! Your stuff is
          <br />
          worth roughly:
        </Title>
      </div>
      <div className="u-mt--md">
        <Highlight>
          {`$`}
          {renderTotalValue()}
        </Highlight>
      </div>
      <TextButton
        id="HOW_IS_THIS_CALCULATED"
        size="large"
        onClick={() => {
          Analytics.event({ category: 'How_Calculated', action: 'FinalScreen' });

          handleScrollToRef(howIsTallyCalculated);
        }}
        className="u-mt--md"
        variant="base"
      >
        How is this calculated?
      </TextButton>
      <Box className="u-mt--md">
        <Text>
          Got more stuff? Add an item below or use our{' '}
          <a
            id="CONTENTS_CALCULATOR"
            href="https://www.state.co.nz/about/contents-sum-insured"
            target="_blank"
            rel="noopener noreferrer"
            className="c-anchor"
          >
            contents calculator
          </a>{' '}
          for a more comprehensive estimate.
        </Text>
        <p className="u-mt--sm">
          <a
            id="FIND_THE_RIGHT_COVER"
            href="https://www.state.co.nz/tally-info"
            className="c-button c-button--icon-right c-button--secondary c-button--large c-button--block"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="c-button__content">
              Find the right cover
              <ChevronRightSVG alt='chevronRight' width={8} height={12} />
            </span>
          </a>
        </p>
        <p className="u-mt--xs">
          <TextButton
            id="RESET"
            className="c-text-button--color-white"
            onClick={() => {
              window.scrollTo(0, 0);
              PLAY_AGAIN();
            }}
          >
            Reset
          </TextButton>
        </p>
      </Box>
    </>
  );

  const renderFooter = () => (
    <>
      <Title level={2}>
        Your stuff is
        <br />
        worth roughly:
      </Title>
      <div className="u-mt--md">
        <Highlight>
          {`$`}
          {renderTotalValue()}
        </Highlight>
      </div>
      <Animation variant="fadeInUp" className="u-mt--md">
        <Box>
          <Text>
            Got more stuff? Use our{' '}
            <a
              id="FOOTER_CONTENTS_CALCULATOR"
              href="https://www.state.co.nz/about/contents-sum-insured"
              target="_blank"
              rel="noopener noreferrer"
              className="c-anchor"
            >
              contents calculator
            </a>{' '}
            for a more comprehensive estimate.
          </Text>
          <p className="u-mt--sm">
            <a
              id="FOOTER_FIND_THE_RIGHT_COVER"
              href="https://www.state.co.nz/tally-info"
              className="c-button c-button--icon-right c-button--secondary c-button--large c-button--block"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="c-button__content">
                Find the right cover
                <ChevronRightSVG alt='chevron right' width={8} height={12} />
              </span>
            </a>
          </p>
          <p className="u-mt--xs">
            <TextButton
              id="FOOTER_RESET"
              className="c-text-button--color-white"
              onClick={() => {
                window.scrollTo(0, 0);
                PLAY_AGAIN();
              }}
            >
              Reset
            </TextButton>
          </p>
        </Box>
      </Animation>
    </>
  );

  return (
    <div>
      <AlertBox
        show={open}
        onClick={() => {
          Analytics.event({ action: 'FinalDropDown', category: 'Close' });

          closeAlertBox();
        }}
      >
        Looks like you’ve got quite a bit of stuff. You may want to consider using our
        <a
          id="MODAL_CONTENTS_CALCULATOR"
          href="https://www.state.co.nz/about/contents-sum-insured"
          target="_blank"
          rel="noopener noreferrer"
          className="c-text-button"
        >
          <span className="c-text-button__content">
            <strong>online contents calculator</strong>
          </span>
        </a>
        to make sure you’ve thought of everything.
      </AlertBox>
      <div className="l-checkout-page">
        <div className="l-checkout-page__indicator">
          <Indicator active={items.length} steps={items.length} />
        </div>
        <div className="l-checkout-page__header">
          <div className="l-item-page__nav u-d--none u-d--lg-flex">
            <StateLogoSVG width={143} height={62} onClick={() => PLAY_AGAIN()} alt='stateLogo' />
            <div className="u-d--none u-d--md-block">
              <TextButton id="SHOW_ITEMS" onClick={() => handleScrollToRef(itemListRef)}>
                View my items
              </TextButton>
            </div>
          </div>
          <div className="l-checkout-page__header-inner">{renderContent()}</div>
        </div>
        <div ref={itemListRef} className="l-checkout-page__content">
          <CSSTransition
            in={!showAllItems}
            timeout={300}
            exit={false}
            classNames="u-animation--fade-in-out"
            unmountOnExit
          >
            <ItemListForm items={ITEMS_WITH_VALUE.slice(0, 3)} />
          </CSSTransition>
          <CSSTransition
            in={showAllItems}
            timeout={300}
            exit={false}
            classNames="u-animation--fade-in-out"
            unmountOnExit
          >
            <ItemListForm items={items} customItems={customItems} />
          </CSSTransition>
          {showAllItems && renderFooter()}
          {ITEMS_WITH_VALUE.length > PAGINATION_LENGTH && (
            <Button
              onClick={() => {
                Analytics.event({ action: 'FinalScreen', category: 'See_All' });
                setShowAllItems(!showAllItems);
                handleScrollToRef(itemListRef);
              }}
              variant="primary-alt"
              className="c-button--no-border"
            >
              <h2>
                {showAllItems ? 'Hide items ' : 'See all items '}
                {showAllItems ? (
                  <ChevronUpSVG alt='chevronUp' width={15} height={10} className="u-ml--xs" />
                ) : (
                  <ChevronDownSVG alt='chevronDown' width={12} height={8} className="u-ml--xs" />
                )}
              </h2>
            </Button>
          )}
          <hr />
          <div ref={howIsTallyCalculated}>
            <Title level={4} className="u-text--left">
              How are Tally values calculated?
            </Title>
          </div>
          <Text size="small" className="u-text--left">
            Tally estimates the replacement cost of your home contents using information supplied by
            Sum Insured Pty Ltd, New Zealand and Australia&apos;s leading provider of home contents
            cost information.
          </Text>
          <Text size="small" className="u-text--left">
            The average values used in Tally are the midpoint between average standard and quality
            standard contents replacement costs of a typical two-person household, consisting of two
            25 to 35 year old adults, with no children residing in a two bedroom, single bathroom,
            single garage dwelling. Specific huh! Average standard represents the most economical
            home contents items and equipment available, whereas quality standard includes above
            average quality items. The maximum values used in Tally represent prestige standard
            (most pricey) contents replacement costs.
          </Text>
          <Text size="small" className="u-text--left">
            We’ve displayed some of the most common household items. But chances are, you have more
            stuff than the items shown in Tally, so to get a more detailed idea of what your stuff
            is worth, you can jump online and use our online contents calculator for a more
            comprehensive estimate. I know, I know, too busy for these details, but it’s important
            to get it right.
          </Text>
          <Text size="small" className="u-text--left">
            Basically, choosing the right level of cover is important to reduce the risk of not
            being fully insured (we call that underinsured). If you still want to learn more, see
            below.
          </Text>
          <hr />
          <Text size="small" className="u-text--left">
            <a
              id="LEARN_ABOUT_CONTENTS_INSURANCE"
              href="https://www.state.co.nz/contents-insurance"
              className="c-text-button c-text-button--base"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="c-text-button__content">
                Learn about the types of contents insurance &gt;
              </span>
            </a>
          </Text>
        </div>
      </div>
    </div>
  );
};

Checkout.defaultProps = {
  customItems: [],
  items: [],
};

Checkout.propTypes = {
  customItems: PropTypes.arrayOf(PropTypes.shape({})),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  playAgain: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    customItems: state.items.customItems,
    items: state.items.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  playAgain: () => dispatch(playAgain()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
