import React from 'react'


const ChevronLeftSvg = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.41 12" width={width} height={height} className={className}>
        <path fill="currentColor" d="M7.41 1.41L2.81 6l4.6 4.59L6 12 0 6l6-6z"/>
    </svg>


  )
}

export default ChevronLeftSvg;