/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */

const Analytics = {
  event: ({ category = '', action = '' }) => {
    if (window.gtag) {
      gtag('event', action, {
        event_category: category,
      });
    }
  },

  pageView: ({ title = '' }) => {
    if (window.gtag) {
      gtag('config', process.env.GA_MEASUREMENT_ID, {
        page_title: title,
        page_path: location.pathname,
      });
    }
  },
};

export default Analytics;
