import React from 'react'


const PlusSvg = ({width, height, className}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={width} height={height} className={className}><path fill="currentColor" d="M14 6h-4V2a2 2 0 0 0-4 0l.07 4H2a2 2 0 0 0 0 4l4.07-.07L6 14a2 2 0 0 0 4 0V9.93l4 .07a2 2 0 0 0 0-4z"></path></svg>

  )
}

export default PlusSvg;