import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

/**
 * @param children
 * @param show
 * @param className
 * @param onClick
 * @returns {*}
 * @constructor
 */
const AlertBox = ({ children, show, className, onClick }) => {
  const CLASS_NAMES = ClassNames('c-alert-box', className);

  return (
    <CSSTransition in={show} timeout={500} classNames="u-animation--slide-up-down" unmountOnExit>
      <div className={CLASS_NAMES}>
        <div className="c-alert-box__content">
          <button type="button" className="c-alert-box__close" onClick={onClick}>
            &times;
          </button>
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

AlertBox.defaultProps = {
  children: null,
  show: false,
  className: '',
  onClick: () => {},
};

AlertBox.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default AlertBox;
