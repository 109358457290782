import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param alt
 * @param className
 * @param src
 * @param width
 * @param height
 * @returns {*}
 * @constructor
 */
const Image = ({ alt, className, src, width, height }) => {
  const CLASS_NAMES = ClassNames('c-image', className, {
    [`c-image--${width}by${height}`]: width && height,
  });

  return (
    <picture className={CLASS_NAMES}>
      <img alt={alt} className="c-image__item" src={src} />
    </picture>
  );
};

Image.defaultProps = {
  alt: '',
  className: '',
  src: 'http://via.placeholder.com/1000',
  width: 3,
  height: 2,
};

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Image;
