import PropTypes from 'prop-types';
import React from 'react';

import Slider from 'react-rangeslider';

/**
 * @param min
 * @param minLabel
 * @param max
 * @param maxLabel
 * @param onChange
 * @param step
 * @param value
 * @returns {*}
 * @constructor
 */
const RangeSlider = ({ min, minLabel, max, maxLabel, onChange, step, value }) => {
  return (
    <div className="c-range-slider">
      <div className="c-range-slider__header">
        <ol className="c-range-slider__labels">
          <li className="c-range-slider__label">{minLabel}</li>
          <li className="c-range-slider__label c-range-slider__label--value">{`$${value}`}</li>
          <li className="c-range-slider__label">{maxLabel}</li>
        </ol>
      </div>
      <div className="c-range-slider__content">
        <div className="rangeslider__container">
          <Slider
            min={min}
            max={max}
            step={step}
            tooltip={false}
            value={value}
            onChange={(newValue) => onChange(newValue)}
          />
        </div>
      </div>
    </div>
  );
};

RangeSlider.defaultProps = {
  min: 0,
  max: 100,
  step: 10,
  value: 50,
  minLabel: '$',
  maxLabel: '$$$',
  onChange: (value) => console.log(`Range slider updated: ${value}`), // eslint-disable-line no-console
};

RangeSlider.propTypes = {
  min: PropTypes.number, // The min amount
  minLabel: PropTypes.string,
  max: PropTypes.number, // The maximum amount
  maxLabel: PropTypes.string,
  step: PropTypes.number, // The amount to step through
  value: PropTypes.number, // the value of the slider
  onChange: PropTypes.func, // The function called when the slider has changed
};

export default RangeSlider;
