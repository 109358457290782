import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ItemCard from '../ItemCard';

/**
 * @param children
 * @param className
 * @returns {*}
 * @constructor
 */
const ItemList = ({ children, className }) => {
  const CLASS_NAMES = ClassNames('c-item-list', className);

  let childIndex = 0;
  const UPDATED_CHILDREN = React.Children.map(children, (child) => {
    childIndex += 1;

    const {
      type,
      props: { value },
    } = child;

    if (type === ItemCard && value !== null) {
      return (
        <li key={childIndex} className="c-item-list__item">
          {React.cloneElement(child, {})}
        </li>
      );
    }

    return null;
  });

  return <div className={CLASS_NAMES}>{UPDATED_CHILDREN}</div>;
};

ItemList.defaultProps = {
  children: null,
  className: '',
};

ItemList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ItemList;
