import React from 'react'


const CouchSVG = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1195.16 807.24" width={width} height={height} className={className}>
    <path d="M997.69 197.3a32.2 32.2 0 0132.11 32.1l-10.38 492.77h80.51v40.89h-86.64c-3.26 4.26-8.2 6.82-15.6 6.82-17.69 0-18.69-14.45-18.69-32.11L965.57 229.4a32.19 32.19 0 0132.12-32.1z" fill="#ff8217"/>
    <path
        d="M997.69 779.38c-15.09 0-21.78-8.27-24.73-15.21-3.22-7.57-3.5-16.75-3.5-26.27l-13.39-508.39v-.13a41.78 41.78 0 0141.6-41.58h.05a41.8 41.8 0 0141.58 41.57v.23l-10.18 483.07h80.31v59.89h-92.07c-4.11 3.63-10.36 6.82-19.67 6.82zM975.07 229.3l13.39 508.35v.12c0 22.61 3.28 22.61 9.23 22.61 5 0 6.95-1.65 8.06-3.09l2.85-3.73h81.83v-21.89h-80.71l10.58-502.34a22.62 22.62 0 00-45.23 0z"/>
    <path d="M978.37 615.19h40.9v37.49h-40.9z"/>
    <path d="M1160.9 748.17a72.08 72.08 0 00-144.16 0V779h144.16z" fill="#fff"/>
    <path d="M1170.4 788.45h-163.16v-40.28a81.58 81.58 0 01163.16 0zm-144.16-19h125.16v-21.28a62.58 62.58 0 00-125.16 0z"/>
    <rect x="940.37" y="760.6" width="236.16" height="37.14" rx="16.63" fill="#fff"/>
    <path d="M1159.9 807.24H957a26.16 26.16 0 01-26.13-26.13v-3.88A26.16 26.16 0 01957 751.1h202.9a26.16 26.16 0 0126.1 26.13v3.88a26.16 26.16 0 01-26.1 26.13zM957 770.1a7.14 7.14 0 00-7.13 7.13v3.88a7.14 7.14 0 007.13 7.13h202.9a7.14 7.14 0 007.13-7.13v-3.88a7.14 7.14 0 00-7.13-7.13z"/>
    <path d="M206.31 197.3a32.2 32.2 0 00-32.11 32.1l10.37 492.77h-80.5v40.89h86.64c3.26 4.26 8.19 6.82 15.6 6.82 17.65 0 18.72-14.45 18.72-32.11l13.38-508.32a32.19 32.19 0 00-32.1-32.15z" fill="#ff8217"/>
    <path
        d="M206.31 779.38c-9.3 0-15.56-3.19-19.67-6.82H94.57v-59.89h80.3L164.7 229.49v-.12a41.8 41.8 0 0141.58-41.57h.06a41.78 41.78 0 0141.57 41.64v.26l-13.38 508.2c0 9.52-.28 18.7-3.5 26.27-2.95 6.94-9.63 15.21-24.72 15.21zm-92.74-25.82h81.83l2.86 3.73c1.1 1.44 3 3.09 8.05 3.09 5.95 0 9.22 0 9.22-22.61v-.25l13.38-508.17a22.61 22.61 0 00-45.21 0l10.57 502.34h-80.7z"/>
    <rect x="52.63" y="396.77" width="1089.88" height="227.37" rx="12" fill="#fff"/>
    <path d="M1130.51 633.64H64.63a21.52 21.52 0 01-21.5-21.5V408.77a21.53 21.53 0 0121.5-21.5h1065.88a21.52 21.52 0 0121.5 21.5v203.37a21.52 21.52 0 01-21.5 21.5zM64.63 406.27a2.5 2.5 0 00-2.5 2.5v203.37a2.5 2.5 0 002.5 2.5h1065.88a2.5 2.5 0 002.5-2.5V408.77a2.5 2.5 0 00-2.5-2.5z"/>
    <path d="M1069.67 347.17c-155.85-2.07-317.18-20.1-481.9-44.32-6.9-1-12.86-7.21-13.11-13.74-3.29-88.1 10-177.37 35.57-267.61 1.91-6.69 8.76-12.13 15.13-12 152.35 2.31 311 20.26 473.76 44.28 6.82 1 12.89 7.19 13.36 13.72 6.16 88.11-4.5 177.37-27.72 267.65-1.76 6.6-8.56 12.02-15.09 12.02z"
          fill="#fff"/>
    <path
        d="M1069.67 356.67h-.13c-137.64-1.83-286.65-15.53-483.15-44.42-11.47-1.66-20.8-11.67-21.22-22.77-3.2-85.49 8.56-174 35.92-270.57C604.18 8.09 614.93-.22 625.55 0c134.29 2 280.78 15.73 475 44.38 11.23 1.65 20.66 11.51 21.45 22.44 6 85.64-3.17 174.18-28 270.7-2.88 10.76-13.54 19.15-24.33 19.15zM625.18 19c-1.77 0-5 2.26-5.81 5.11-26.81 94.61-38.37 181.19-35.22 264.65.07 1.75 2.43 4.32 5 4.69 195.62 28.76 343.83 42.4 480.58 44.22 2 0 5.18-2.34 5.86-4.94 24.3-94.45 33.27-181 27.43-264.56-.13-1.87-2.64-4.6-5.26-5C904.41 34.65 758.68 21 625.22 19z"/>
    <path d="M622 328.53c-163.86 21-324.85 34-481.79 32-6.56-.09-13.34-5.66-15-12.33-21.74-90.12-29.21-179.46-19.1-268 .75-6.55 7-12.63 13.78-13.51 161.66-20.69 319.82-33.76 473.33-32 6.41.08 13.23 5.64 15 12.31 24.35 90.17 34.56 179.5 27.35 268-.51 6.54-6.68 12.64-13.57 13.53z" fill="#fff"/>
    <path
        d="M171.71 370.23q-15.93 0-31.58-.2c-10.83-.15-21.41-8.73-24.1-19.53-22.66-93.95-29.16-185.23-19.32-271.38 1.24-10.81 10.9-20.41 22-21.85 180.61-23.18 335.85-33.66 474.62-32.08 10.65.14 21.22 8.65 24.07 19.38 25.35 93.86 34.65 185.12 27.64 271.19-.85 11-10.42 20.72-21.78 22.19-169.55 21.68-317.67 32.28-451.55 32.28zM565.74 44c-130.88 0-276.68 10.53-444.6 32.08-2.66.34-5.32 3.17-5.55 5.16-9.59 83.93-3.23 173 18.9 264.69.65 2.62 3.84 5 5.89 5.06 141.19 1.8 298.36-8.64 480.44-31.92 2.62-.34 5.13-3 5.28-4.85 6.84-83.92-2.27-173-27.05-264.78-.72-2.71-4-5.26-5.95-5.29Q579.54 44 565.74 44z"/>
    <circle cx="380.49" cy="197.6" r="15.97"/>
    <path d="M1161.69 519.39V281a17.78 17.78 0 00-17.69-17.73H51.57A17.78 17.78 0 0033.85 281v238.39" fill="#ff8591"/>
    <path d="M1171.19 519.39h-19V281a8.29 8.29 0 00-8.25-8.24H51.57a8.29 8.29 0 00-8.22 8.24v238.39h-19V281a27.34 27.34 0 0127.19-27.24H1144a27.35 27.35 0 0127.19 27.24z"/>
    <path
        d="M1122.89 493.57h40c12.58 0 22.77 11.06 22.77 24.7v1.12c0 13.63-10.19 24.69-22.77 24.69l-33.77.5h-2.25c-74 4.28-80 34.86-165.09 34.86-91.5 0-91.5-35.36-183-35.36s-91.49 35.36-183 35.36-91.5-35.36-183-35.36-91.5 35.36-183 35.36c-85.09 0-89.91-30.58-163.77-34.86H65l-32.74-.5C19.69 544.08 9.5 533 9.5 519.39c0-13.64 10.19-24.7 22.77-24.7h35.79"
        fill="#ff8591"/>
    <path
        d="M961.78 588.94c-47.52 0-71.62-9.31-94.92-18.32-22.68-8.76-44.1-17-88.08-17s-65.39 8.28-88.07 17c-23.3 9-47.4 18.32-94.93 18.32s-71.63-9.31-94.93-18.32c-22.68-8.76-44.09-17-88.07-17s-65.4 8.28-88.08 17c-23.3 9-47.4 18.32-94.92 18.32s-71.31-9.32-94.3-18.33C116.31 563.1 98.2 556 65.73 554.08h-.86l-32.69-.5C14.43 553.53 0 538.21 0 519.39s14.48-34.2 32.27-34.2h35.79a9.5 9.5 0 010 19H32.27C25 504.19 19 511 19 519.39s6 15.19 13.27 15.19h.15l34.14.52c35.66 2.06 56.09 10.07 75.85 17.82 22.33 8.75 43.42 17 87.37 17s65.4-8.28 88.08-17c23.3-9 47.4-18.32 94.92-18.32s71.62 9.32 94.92 18.32c22.68 8.76 44.1 17 88.08 17s65.4-8.28 88.08-17c23.3-9 47.4-18.32 94.92-18.32s71.62 9.32 94.93 18.32c22.67 8.76 44.09 17 88.07 17s65.41-8.28 88.1-17c20-7.74 40.74-15.74 76.44-17.8H1129.05l33.84-.5c7.32 0 13.27-6.81 13.27-15.19v-1.12c0-8.38-6-15.2-13.27-15.2h-40a9.5 9.5 0 010-19h40c17.79 0 32.27 15.34 32.27 34.2v1.12c0 18.83-14.43 34.15-32.19 34.19l-35.82.5c-32.56 1.92-51 9-70.43 16.54-23.31 8.97-47.42 18.28-94.94 18.28z"/>
    <circle cx="848.37" cy="197.6" r="15.97"/>
    <path d="M187.26 615.2h40.9v37.49h-40.9z"/>
    <path d="M637.1 361.63a55.93 55.93 0 01-79.1 2.11" fill="#ff8591"/>
    <path d="M596.5 388.55a65.19 65.19 0 01-45-17.91 9.5 9.5 0 1113.07-13.79 46.49 46.49 0 0065.63-1.75 9.5 9.5 0 1113.8 13.07 65.26 65.26 0 01-47.5 20.38z"/>
    <path d="M43.1 748.17a72.08 72.08 0 01144.16 0V779H43.1z" fill="#fff"/>
    <path d="M196.76 788.45H33.6v-40.28a81.67 81.67 0 0181.56-81.59 81.58 81.58 0 0181.58 81.56zm-144.16-19h125.16v-21.28a62.58 62.58 0 00-62.58-62.59 62.65 62.65 0 00-62.58 62.59z"/>
    <rect x="27.46" y="760.59" width="236.16" height="37.14" rx="16.63" fill="#fff"/>
    <path d="M247 807.23H44.09A26.16 26.16 0 0118 781.1v-3.88a26.16 26.16 0 0126.13-26.13H247a26.16 26.16 0 0126.13 26.13v3.88A26.16 26.16 0 01247 807.23zM44.09 770.09a7.14 7.14 0 00-7.09 7.13v3.88a7.14 7.14 0 007.13 7.13H247a7.14 7.14 0 007.13-7.13v-3.88a7.14 7.14 0 00-7.13-7.13z"/>
</svg>



  )
}

export default CouchSVG;