import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import {thunk} from 'redux-thunk';

import items from './reducers/items';

const COMBINED_REDUCERS = combineReducers({
  items,
});

const persistConfig = { key: 'root', storage };
const persistedReducer = persistReducer(persistConfig, COMBINED_REDUCERS);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const store = createStore(persistedReducer, {}, applyMiddleware(thunk));
  const persistor = persistStore(store);
  return { store, persistor };
};
