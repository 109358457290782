import React from 'react'


const PhoneSVG = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473.78 753.72" width={width} height={height} className={className}>
    <path d="M421.58 236.33h42.7v30h-42.7zm-1.41-76.53l42.7.2-.14 30.05-42.7-.2z"/>
    <path
        d="M464.28 275.88h-42.7a9.5 9.5 0 01-9.5-9.5v-30a9.5 9.5 0 019.5-9.5h42.7a9.5 9.5 0 019.5 9.5v30a9.5 9.5 0 01-9.5 9.5zm-33.2-19h23.7v-11h-23.7zm31.65-57.33h-.05l-42.7-.2a9.49 9.49 0 01-9.45-9.54l.14-30.05a9.51 9.51 0 019.5-9.46h.05l42.69.2a9.51 9.51 0 019.46 9.55l-.14 30a9.5 9.5 0 01-9.5 9.5zm-33.16-19.15l23.7.11.05-11-23.69-.11z"/>
    <rect x="-141.97" y="163.34" width="733.06" height="427.03" rx="37.23" transform="rotate(-89.73 224.561 376.857)" fill="#f2f2f2"/>
    <path
        d="M399.3 753.72h-.22l-352.57-1.67A46.72 46.72 0 010 705.1L3.1 46.51A46.8 46.8 0 0149.83 0h.22l352.57 1.66a46.74 46.74 0 0146.51 47L446 707.21a46.76 46.76 0 01-46.73 46.51zM49.83 19A27.77 27.77 0 0022.1 46.6L19 705.19a27.72 27.72 0 0027.6 27.86l352.57 1.67a27.74 27.74 0 0027.83-27.6l3.1-658.6a27.74 27.74 0 00-27.6-27.86L50 19z"/>
    <path d="M12.39 90.36l427 2-2.66 566-427-2z" fill="#009fde"/>
    <path d="M436.75 667.85h-.05l-427-2a9.51 9.51 0 01-9.46-9.55l2.67-566a9.49 9.49 0 019.5-9.45l427 2a9.49 9.49 0 019.45 9.54l-2.66 566a9.51 9.51 0 01-9.5 9.45zm-417.48-21l408 1.93 2.58-547-408-1.92z"/>
    <circle cx="153.42" cy="522.88" r="41.28" fill="#f2f2f2"/>
    <path d="M153.42 573.66a50.78 50.78 0 1150.78-50.78 50.84 50.84 0 01-50.78 50.78zm0-82.56a31.78 31.78 0 1031.78 31.78 31.82 31.82 0 00-31.78-31.78z"/>
    <circle cx="144.11" cy="521.96" r="17.65"/>
    <circle cx="283.61" cy="522.63" r="41.28" fill="#f2f2f2"/>
    <path d="M283.61 573.41a50.78 50.78 0 1150.78-50.78 50.84 50.84 0 01-50.78 50.78zm0-82.56a31.78 31.78 0 1031.78 31.78 31.82 31.82 0 00-31.78-31.78z"/>
    <circle cx="274.3" cy="521.7" r="17.65"/>
    <path d="M280.9 59.48H175.35a9.5 9.5 0 010-19H280.9a9.5 9.5 0 010 19z"/>
</svg>


  )
}

export default PhoneSVG;