import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param children
 * @param component
 * @param props
 * @returns {*}
 * @constructor
 */
const Typography = ({ children, component, ...props }) => {
  const Component = component;

  return <Component {...props}>{children}</Component>;
};

Typography.defaultProps = {
  children: null,
  component: 'p',
};

Typography.propTypes = {
  children: PropTypes.node,
  // What DOM node the component will be.
  component: PropTypes.string,
};

export default Typography;
