export const ADD_CUSTOM_ITEM = 'ADD_CUSTOM_ITEM';
export const RESET_ITEMS = 'RESET_ITEMS';
export const REMOVE_CUSTOM_ITEM = 'REMOVE_CUSTOM_ITEM';
export const SET_ACTIVE_ITEM_ID = 'SET_ACTIVE_ITEM_ID';
export const SET_COMPLETED = 'SET_COMPLETED';
export const SET_ITEM_VALUE = 'SET_ITEM_VALUE';
export const SET_CUSTOM_ITEM_VALUE = 'SET_CUSTOM_ITEM_VALUE';
export const SET_STARTED = 'SET_STARTED';
export const SET_SHOW_DESCRIPTION = 'SET_SHOW_DESCRIPTION';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_ITEMS = 'UPDATE_ITEMS';
export const UPDATE_ITEMS_FAILURE = 'UPDATE_ITEMS_FAILURE';
export const UPDATE_ITEMS_SUCCESS = 'UPDATE_ITEMS_SUCCESS';
