import ClassNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import * as Yup from 'yup';

import Button from '../../components/Button';
import ItemCard from '../../components/ItemCard';
import DeleteSVG from '../../components/ItemCard/delete.svg';
import ItemList from '../../components/ItemList';
import PlaceholderIcon from '../../components/PlaceholderIcon';
import Typography from '../../components/Typography';
import {
  addCustomItem,
  removeCustomItem,
  setCustomItemValue,
  setItemValue,
} from '../../store/actions/items';
import Analytics from '../../utils/Analytics';
import ChevronRightSVG from '../../components/images/ChevronRightSvg';
import PlusSVG from '../../components/images/PlusSvg';

const { Text, Title } = Typography;

/* eslint-disable jsx-a11y/label-has-associated-control */

/**
 * @param className
 * @param ADD_CUSTOM_ITEM
 * @param customItems
 * @param items
 * @param REMOVE_CUSTOM_ITEM
 * @param SET_ITEM_VALUE
 * @param SET_CUSTOM_ITEM_VALUE
 * @returns {*}
 * @constructor
 */
const ItemListForm = ({
  className,
  addCustomItem: ADD_CUSTOM_ITEM,
  customItems,
  items,
  removeCustomItem: REMOVE_CUSTOM_ITEM,
  setItemValue: SET_ITEM_VALUE,
  setCustomItemValue: SET_CUSTOM_ITEM_VALUE,
}) => {
  const [showForm, setShowForm] = useState(false);

  const [customTitle, setCustomTitle] = useState('');
  const [customValue, setCustomValue] = useState('');

  const formRef = useRef(null);

  // Handlers
  // -------------------------------------------

  const handleCustomTitle = (e) => {
    setCustomTitle(e.target.value);
  };

  const handleCustomValue = (e) => {
    setCustomValue(e.target.value);
  };

  useEffect(() => {
    setCustomTitle(customTitle);
  }, [customTitle]);

  useEffect(() => {
    setCustomValue(customValue);
  }, [customValue]);

  const handleCancelCustomItem = () => {
    setCustomValue('');
    setCustomTitle('');
    setShowForm(false);
  };

  /**
   * Scroll to a ref
   *
   * @param ref
   */
  const handleScrollToRef = (ref) => {
    window.requestAnimationFrame(() =>
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    );
  };

  /**
   * Update the item value
   *
   * @param id
   * @param value
   */
  const handleChangeItem = ({ id, value }) => {
    if (value !== null) {
      Analytics.event({ action: 'YourItems', category: 'Edit_Item' });
    }

    SET_ITEM_VALUE({ id, value });
  };

  /**
   * Update the item value
   *
   * @param id
   * @param value
   */
  const handleChangeCustomItem = ({ id, value }) => {
    SET_CUSTOM_ITEM_VALUE({ id, value });
  };

  /**
   * Remove value of item from list
   *
   * @param id
   */
  const handleDeleteItem = (id) => {
    Analytics.event({ action: 'YourItems', category: 'Delete_Item' });

    handleChangeItem({ id, value: null });
  };

  /**
   * Remove an item from the custom items array
   *
   * @param id
   */
  const handleDeleteCustomItem = (id) => {
    REMOVE_CUSTOM_ITEM(id);
  };

  // Render
  // -------------------------------------------

  const renderForm = () => {
    return (
      <CSSTransition
        in={showForm}
        timeout={300}
        classNames="u-animation--fade-in-out"
        unmountOnExit
      >
        <Formik
          enableReinitialize
          initialValues={{ title: customTitle, value: customValue }}
          validationSchema={Yup.object({
            title: Yup.string().max(255, 'Must be 255 characters or less').required('Required'),
            value: Yup.number()
              .typeError('Value must contain numbers only')
              .min(1, 'Please enter a value')
              .required('Required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            Analytics.event({ action: 'YourItems', category: 'Save_CustomItem' });
            ADD_CUSTOM_ITEM({
              title: values.title,
              value: values.value,
            });
            handleCancelCustomItem();
            setSubmitting(false);
          }}
        >
          <Form>
            <div ref={formRef} className="l-form-group c-item-card">
              <div className="c-item-card__inner">
                <div className="c-item-card__media c-item-card__media--primary">
                  <PlaceholderIcon />
                </div>
                <div className="c-item-card__content">
                  <Title level={4}>{customTitle === '' ? 'Title' : customTitle}</Title>
                  <Text size="small" className="u-mt--0">
                    {`$${customValue === '' ? '0.00' : customValue}`}
                  </Text>
                </div>
                <div className="c-item-card__actions">
                  <button
                    id="CANCEL_CUSTOM_ITEM"
                    className="c-item-card__action"
                    onClick={() => handleCancelCustomItem()}
                    type="button"
                  >
                    <img src={DeleteSVG} alt='delete' width={20} height={20} />
                  </button>
                </div>
              </div>

              <div className="l-form-group__header">
                <label htmlFor="title" className="c-label">
                  Title
                </label>
              </div>
              <div className="l-form-group__content">
                <Field
                  name="title"
                  id="title"
                  type="text"
                  className="c-form-field"
                  placeholder="DJ Gear"
                  value={customTitle}
                  onChange={(e) => handleCustomTitle(e)}
                />
                <ErrorMessage
                  name="title"
                  component="p"
                  className="c-message c-message--error u-mt--xs"
                />
              </div>

              <div className="l-form-group__header">
                <label htmlFor="value" className="c-label">
                  Value
                </label>
              </div>
              <div className="l-form-group__content">
                <Field
                  name="value"
                  id="value"
                  type="text"
                  className="c-form-field"
                  placeholder="5000"
                  value={customValue}
                  onChange={(e) => handleCustomValue(e)}
                />
                <ErrorMessage
                  name="value"
                  component="p"
                  className="c-message c-message--error u-mt--xs"
                />
              </div>
              <p className="u-mt--sm">
                <Button
                  id="SAVE_CUSTOM_ITEM"
                  type="submit"
                  variant="primary-alt"
                  size="small"
                  bordered
                  className="c-button--icon-right"
                >
                  Save
                  <ChevronRightSVG alt='chevronRight' width={8} height={12} />
                </Button>
              </p>
            </div>
          </Form>
        </Formik>
      </CSSTransition>
    );
  };

  const CLASS_NAMES = ClassNames('l-item-list-form', className);

  return (
    <div className={CLASS_NAMES}>
      <div className="l-item-list-form__header">
        <Title>Your items</Title>
        <Button
          id="ADD_CUSTOM_ITEM"
          onClick={() => {
            Analytics.event({ action: 'YourItems', category: 'Custom_Item' });
            setShowForm(true);
            handleScrollToRef(formRef);
          }}
          variant="primary-alt"
          size="small"
          bordered
          className="c-button--icon-right"
        >
          Add custom item
          <PlusSVG alt='plus' width={10} height={10} />
        </Button>
      </div>
      <div className="l-item-list-form__content">
        {renderForm()}
        <ItemList>
          {customItems.map((item) => (
            <ItemCard
              {...item}
              onChange={(value) => handleChangeCustomItem(value)}
              onDelete={() => handleDeleteCustomItem(item.id)}
            />
          ))}
          {items.map((item) => (
            <ItemCard
              {...item}
              onChange={(value) => handleChangeItem(value)}
              onDelete={() => handleDeleteItem(item.id)}
            />
          ))}
        </ItemList>
      </div>
    </div>
  );
};

ItemListForm.defaultProps = {
  className: '',
  customItems: [],
  items: [],
};

ItemListForm.propTypes = {
  className: PropTypes.string,
  customItems: PropTypes.arrayOf(PropTypes.shape({})),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  addCustomItem: PropTypes.func.isRequired,
  removeCustomItem: PropTypes.func.isRequired,
  setCustomItemValue: PropTypes.func.isRequired,
  setItemValue: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addCustomItem: (state) => dispatch(addCustomItem(state)),
  removeCustomItem: (state) => dispatch(removeCustomItem(state)),
  setCustomItemValue: (state) => dispatch(setCustomItemValue(state)),
  setItemValue: (state) => dispatch(setItemValue(state)),
});

export default connect(null, mapDispatchToProps)(ItemListForm);
