import React from 'react'


const ChevronDownSvg = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6.03" width={width} height={height} className={className}>
        <path d="M5.12 6L.21 1.19a.69.69 0 010-1 .7.7 0 011 0l3.9 3.85L8.8.21a.71.71 0 011 0 .69.69 0 010 1z" fill="currentColor"/>
    </svg>
  )
}

export default ChevronDownSvg;