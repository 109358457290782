import React from 'react'


const RingSVG = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560.36 781.75" width={width} height={height} className={className} >
    <path d="M98.6 20.53l6.4 39.3 36 17-35.35 18.24-5 39.5-28.3-28L33.21 114l17.9-35.57L32 43.5l39.36 6 27.28-29z" fill="#fff"/>
    <path d="M107.63 154.86L69.2 116.81 16.07 126.9l24.31-48.3-26-47.41 53.45 8.19L104.87 0l8.73 53.37 48.9 23.05-48.06 24.8zM75.46 96.28l18.17 18 3.23-25.35 22.72-11.73-23.12-10.91-4.13-25.23-17.51 18.61-25.27-3.87 12.29 22.42-11.49 22.83z" fill="#241f20"/>
    <circle cx="223.94" cy="192.61" r="71.72" fill="#fff"/>
    <path d="M223.94 273.83a81.22 81.22 0 1181.22-81.22 81.32 81.32 0 01-81.22 81.22zm0-143.44a62.22 62.22 0 1062.22 62.22 62.29 62.29 0 00-62.22-62.22z" fill="#241f20"/>
    <circle cx="352.72" cy="192.61" r="71.72" fill="#fff"/>
    <path d="M352.72 273.83a81.22 81.22 0 1181.22-81.22 81.32 81.32 0 01-81.22 81.22zm0-143.44a62.22 62.22 0 1062.22 62.22 62.29 62.29 0 00-62.22-62.22z" fill="#241f20"/>
    <circle cx="353.39" cy="195.59" r="19.26" fill="#241f20"/>
    <circle cx="223.94" cy="195.59" r="19.26" fill="#241f20"/>
    <path d="M280.18 230.9C130.69 230.9 9.5 352.08 9.5 501.58s121.19 270.67 270.68 270.67 270.68-121.18 270.68-270.67S429.67 230.9 280.18 230.9zm0 464.35c-107 0-193.68-86.71-193.68-193.67S173.18 307.9 280.18 307.9s193.68 86.71 193.68 193.68-86.68 193.67-193.68 193.67z" fill="#fdeb00"/>
    <path
        d="M280.18 781.75a280.17 280.17 0 11198.12-82.06 278.35 278.35 0 01-198.12 82.06zm0-541.35a261.19 261.19 0 10184.68 76.5 259.47 259.47 0 00-184.68-76.5zm0 464.35a203.16 203.16 0 11143.68-59.51 201.87 201.87 0 01-143.68 59.51zm0-387.35C178.62 317.4 96 400 96 501.58s82.62 184.17 184.18 184.17 184.18-82.62 184.18-184.17S381.74 317.4 280.18 317.4z"
        fill="#241f20"/>
    </svg>

  )
}

export default RingSVG;