import React from 'react'


const ChevronRightSvg = ({width, height, className}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.41 12" width={width} height={height} className={className}>
    <path fill="currentColor" d="M1.41 0l6 6-6 6L0 10.59 4.59 6 0 1.41z"/>
</svg>

  )
}

export default ChevronRightSvg;