import ClassNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';

import ChevronRightSVG from '../../components/images/ChevronRightSvg';
import Button from '../Button';
import Image from '../Image';
import PlaceholderIcon from '../PlaceholderIcon';
import Typography from '../Typography';
import DeleteSVG from './delete.svg';
import EditSVG from './edit.svg';

const { Text, Title } = Typography;

/**
 * @param id
 * @param editing
 * @param title
 * @param image
 * @param palette
 * @param value
 * @param onChange
 * @param onDelete
 * @param className
 * @returns {*}
 * @constructor
 */
const ItemCard = ({ id, editing, title, image, palette, value, onChange, onDelete, className }) => {
  const [isEditing, setIsEditing] = useState(editing);
  const CLASS_NAMES = ClassNames('c-item-card', className, {
    'is-editing': isEditing,
  });

  return (
    <div className={CLASS_NAMES}>
      <div className="c-item-card__inner">
        <div className={`c-item-card__media c-item-card__media--${palette}`}>
          {image.url ? (
            <Image src={image.url} atl={image.alt} width={5} height={3} />
          ) : (
            <PlaceholderIcon />
          )}
        </div>
        <div className="c-item-card__content">
          <Title level={4} truncate>
            {title}
          </Title>
          <Text size="small" className="u-mt--0">
            {`$${value}` || '-'}
          </Text>
        </div>
        <div className="c-item-card__actions">
          <button
            id="EDIT_CUSTOM_ITEM"
            className="c-item-card__action"
            onClick={() => setIsEditing(!isEditing)}
            type="button"
          >
            <img src={EditSVG} alt='edit' width={20} height={20} />
          </button>
          <button
            id="DELETE_CUSTOM_ITEM"
            className="c-item-card__action"
            onClick={() => onDelete()}
            type="button"
          >
            <img src={DeleteSVG} alt='delete' width={20} height={20} />
          </button>
        </div>
      </div>
      {isEditing ? (
        <div className="c-item-card__form">
          <Formik
            initialValues={{ value }}
            validationSchema={Yup.object({
              value: Yup.number().typeError('Value must contain numbers only').required('Required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              onChange({ id, value: values.value });
              setSubmitting(false);
              setIsEditing(false);
            }}
          >
            <Form>
              <div className="l-form-group">
                <div className="l-form-group__content">
                  <Field name="value" type="text" className="c-form-field" />
                  <ErrorMessage
                    name="value"
                    component="p"
                    className="c-message c-message--error u-mt--xs"
                  />
                </div>
              </div>
              <p className="u-mt--xs">
                <Button
                  id="SAVE_CUSTOM_ITEM"
                  type="submit"
                  variant="primary-alt"
                  size="small"
                  bordered
                  className="c-button--icon-right"
                >
                  Save
                  <ChevronRightSVG alt='chevronRight' width={8} height={12} />
                </Button>
              </p>
            </Form>
          </Formik>
        </div>
      ) : null}
    </div>
  );
};

ItemCard.defaultProps = {
  editing: false,
  title: 'Lorum Ipsum',
  image: { url: undefined, alt: '' },
  palette: 'primary',
  value: 0,
  onChange: () => {},
  onDelete: () => {},
  className: '',
};

ItemCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  editing: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.object,
  palette: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
};

export default ItemCard;
