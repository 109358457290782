import React from 'react'


const LaptopSVG = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1057.33 659.77" width={width} height={height} className={className}>
    <rect x="74.48" y="9.5" width="904.58" height="501.98" rx="31" fill="#f2f2f2"/>
    <path d="M948.06 521H105.48A40.55 40.55 0 0165 480.48V40.5A40.55 40.55 0 01105.48 0h842.58a40.55 40.55 0 0140.5 40.5v440a40.55 40.55 0 01-40.5 40.5zM105.48 19A21.53 21.53 0 0084 40.5v440a21.52 21.52 0 0021.5 21.5h842.56a21.52 21.52 0 0021.5-21.5v-440a21.52 21.52 0 00-21.5-21.5z"/>
    <path d="M9.5 650.27c22.3-50 45.22-110.76 67.53-160.78H979c22.31 50 46.51 110.77 68.8 160.78-46.51-.3-994.66-.17-1038.33 0z" fill="#f2f2f2"/>
    <path
        d="M9.5 659.77A9.5 9.5 0 01.82 646.4c11.09-24.85 22.55-52.93 33.64-80.08 11.15-27.31 22.67-55.55 33.89-80.7A9.52 9.52 0 0177 480h902a9.5 9.5 0 018.68 5.63c11.24 25.21 23.14 53.55 34.64 81 11.36 27.06 23.1 55 34.16 79.83a9.5 9.5 0 01-8.68 13.37h-.06c-47.49-.31-999-.15-1038.23 0zM83.21 499c-10.31 23.44-20.9 49.37-31.16 74.51-9.2 22.53-18.66 45.7-28 67.24 120.18-.13 886.66-.25 1009.18 0-9.35-21.4-19-44.42-28.41-66.8-10.61-25.28-21.55-51.36-32-74.94z"/>
    <path d="M74.48 68.36h904.58v373.73H74.48z" fill="#009fde"/>
    <path d="M979.06 451.59H74.48a9.5 9.5 0 01-9.5-9.5V68.36a9.5 9.5 0 019.5-9.5h904.58a9.5 9.5 0 019.5 9.5v373.73a9.5 9.5 0 01-9.5 9.5zM84 432.59h885.56V77.86H84z"/>
    <rect x="361.84" y="229.33" width="239.41" height="169.33" rx="9.5" fill="#ff8591"/>
    <path d="M221 115.68h214.54V288.9H221z" fill="#fff200"/>
    <path d="M435.54 298.4H221a9.5 9.5 0 01-9.5-9.5V115.68a9.5 9.5 0 019.5-9.5h214.54a9.5 9.5 0 019.5 9.5V288.9a9.5 9.5 0 01-9.5 9.5zm-205-19H426V125.18H230.5z"/>
    <rect x="703.28" y="118.67" width="188.45" height="166.61" rx="9.5" fill="#ff8591"/>
    <circle cx="323.35" cy="203.16" r="42.91" fill="#f2f2f2"/>
    <path d="M323.35 255.57a52.41 52.41 0 1152.41-52.41 52.47 52.47 0 01-52.41 52.41zm0-85.82a33.41 33.41 0 1033.41 33.41 33.45 33.45 0 00-33.41-33.41z"/>
    <circle cx="324.74" cy="202.08" r="14.71"/>
    <circle cx="786.26" cy="203.16" r="42.91" transform="rotate(-67.5 786.26 203.155)" fill="#f2f2f2"/>
    <path d="M786.26 255.57a52.41 52.41 0 1152.41-52.41 52.47 52.47 0 01-52.41 52.41zm0-85.82a33.41 33.41 0 1033.41 33.41 33.45 33.45 0 00-33.41-33.41z"/>
    <circle cx="787.65" cy="202.08" r="14.71"/>
    <path d="M984.16 612.05H77l33.12-79h843.46zM104 571.28h856.69" fill="#f2f2f2"/>
    <path d="M984.16 621.55H77a9.5 9.5 0 01-8.76-13.17l33.12-79a9.5 9.5 0 018.76-5.83h843.46a9.51 9.51 0 018.86 6.07l30.58 79a9.5 9.5 0 01-8.86 12.93zm-892.85-19h879l-8.45-21.84a8.77 8.77 0 01-1.15.07H104a9.51 9.51 0 01-3.34-.6zm17.1-40.77h846.1l-7.44-19.22h-830.6z"/>
    <path
        d="M121.42 610.78l29.77-79m18.24 79l26.42-79m21.59 79l23.07-79m24.94 79l19.72-79m28.3 79l16.36-79m31.65 79l13-79m35 79l9.66-79m38.35 79l6.31-79m41.71 79l3-79m45.06 79l-.41-79m48.42 79l-3.76-79m51.77 79l-7.11-79m55.12 79l-10.46-79m58.48 79l-13.82-79m61.83 79l-17.17-79m65.18 79l-20.52-79m68.53 79l-23.87-79m71.88 79l-27.22-79m-212.88 80.28l-337.44-.53 6.79-40.25H693l4.57 40.78z"
        fill="#f2f2f2"/>
    <path
        d="M697.56 621.56L360.1 621a9.5 9.5 0 01-9.35-11l6.79-40.25a9.61 9.61 0 011.75-4.1l5.83-35.35 18.74 3.08-4.68 28.45h26.73l3.81-31.14 18.86 2.3-3.53 28.84h26.83l2.46-30.83 18.94 1.52-2.34 29.23h26.9l1.16-30.31 19 .7-1.1 29.64h26.92l-.16-29.94 19-.1.16 30h26.88l-1.41-29.54 19-.9 1.45 30.44h26.86l-2.6-29.1 18.92-1.7 2.78 30.84h26.81L677.68 533l18.84-2.5 4.78 36.14a9.44 9.44 0 011.13 3.54l3.63 32.36.92 6.95h-.14L707 611a9.49 9.49 0 01-9.44 10.56zm-39.22-19.06h28.55L684 580.78h-27.61zm-47.69-.08l28.61.05-2-21.69h-27.64zm-47.65-.07h28.64l-1-21.61h-27.77zm-47.65-.08l28.65.05-.12-21.54h-27.73zm-47.62-.07h28.61l.8-21.46h-27.7zm-47.6-.08h28.54l1.71-21.39h-27.65zm-47.57-.07h28.43l2.61-21.31h-27.55zm-242.25 12.08l-17.78-6.7 29.77-79 17.78 6.7zm798.33-.26l-27.22-79 18-6.19 27.22 79zm-750.2-.08l-18-6 26.42-79 18 6zm702.08-.26l-23.87-79 18.18-5.5 23.87 79zm-654-.09l-18.24-5.32 23.07-79 18.24 5.32zm605.85-.27l-20.52-79 18.38-4.78 20.52 79zm-557.74-.09l-18.44-4.6 19.72-79 18.44 4.6zm509.64-.28l-17.17-79 18.56-4 17.17 79zm-461.54-.09l-18.6-3.86 16.36-79 18.6 3.86zm413.45-.29l-13.82-79 18.72-3.28 13.82 79z"/>
    <path d="M755.83 240l-91.28 61.16 25.3 14.69-30.55 52.58 27.33 15.87 30.55-52.64 24.4 14.16z" fill="#ff8217"/>
    <path
        d="M686.63 393.8a9.58 9.58 0 01-4.77-1.28l-27.33-15.87a9.51 9.51 0 01-3.45-13l25.79-44.41-17.09-9.92a9.51 9.51 0 01-.52-16.11l91.28-61.16a9.5 9.5 0 0114.7 9.16L751 347.09a9.5 9.5 0 01-14.18 7l-16.18-9.4-25.78 44.43a9.5 9.5 0 01-5.78 4.41 9.73 9.73 0 01-2.45.27zM672.28 365l10.9 6.33L709 326.89a9.51 9.51 0 0113-3.45l12.11 7 9.54-70.89-61.12 40.95 12.14 7.05a9.51 9.51 0 013.45 13zM484.11 352.73c-24.65 0-49.58-7-66.84-20.9a9.5 9.5 0 0111.94-14.78c27.31 22.06 82.74 22.18 107.84.24a9.5 9.5 0 1112.5 14.3c-16.11 14.09-40.64 21.14-65.44 21.14z"/>
    </svg>

  )
}

export default LaptopSVG;