import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Typography from './Typography';

/**
 * @param className
 * @param variant
 * @param props
 * @returns {*}
 * @constructor
 */
const Text = ({ className, size, ...props }) => {
  const CLASS_NAMES = ClassNames('c-typography', className, {
    [`c-typography--${size}`]: size,
  });

  return <Typography {...props} className={CLASS_NAMES} component="p" />;
};

Text.defaultProps = {
  className: '',
  children: null,
  size: null,
};

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'large']),
};

export default Text;
