import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

/**
 * @param children
 * @param className
 * @param showDelay
 * @param showDuration
 * @param tipText
 * @returns {*}
 * @constructor
 */
const Tooltip = ({ children, className, showDelay, showDuration, tipText }) => {
  const CLASS_NAMES = ClassNames('c-tooltip', className);

  const [show, setShow] = useState(0);

  let timerShow = () => {};
  let timerHide = () => {};

  useEffect(() => {
    return () => {
      clearTimeout(timerShow);
      clearTimeout(timerHide);
    };
  }, []);

  const handleShow = () => {
    if (show === 0) {
      timerShow = setTimeout(() => {
        setShow(1);

        timerHide = setTimeout(() => {
          setShow(2);
        }, showDuration);
      }, showDelay);
    }
  };

  useEffect(() => {
    handleShow();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div className={CLASS_NAMES}>
      {children}
      <CSSTransition
        in={show === 1}
        timeout={350}
        classNames="u-animation--fade-up-down"
        unmountOnExit
      >
        <div className="c-tooltip__popup">
          <div className="c-tooltip__popup__arrow" />
          <div className="c-tooltip__popup__label">{tipText}</div>
        </div>
      </CSSTransition>
    </div>
  );
};

Tooltip.defaultProps = {
  children: null,
  className: '',
  showDelay: 500,
  showDuration: 3000,
  tipText: '',
};

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showDelay: PropTypes.number,
  showDuration: PropTypes.number,
  tipText: PropTypes.string,
};

export default Tooltip;
