/* eslint-disable react-hooks/rules-of-hooks */
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import Animation from '../../components/Animation';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Indicator from '../../components/Indicator';
import RangeSlider from '../../components/RangeSlider';
import TextButton from '../../components/TextButton';
import Tooltip from '../../components/Tooltip';
import Typography from '../../components/Typography';
import {
  playAgain,
  setItemValue,
  setNextItemAsActive,
  setPreviousItemAsActive,
} from '../../store/actions/items';
import Analytics from '../../utils/Analytics';
import ItemListForm from '../ItemListForm';
import CheckSVG from '../../components/images/CheckSvg';
import ChevronDownSVG from '../../components/images/ChevronDownSvg';
import ChevronLeftSVG from '../../components/images/ChevronLeftSvg';
import ChevronRightSVG from '../../components/images/ChevronRightSvg';
import ChevronUpSVG from '../../components/images/ChevronUpSvg';
import PlusSVG from '../../components/images/PlusSvg';
import StateLogoSVG from '../../components/images/StateLogoSvg';

const { Text, Title } = Typography;

let timeout;
let inputTimeout;

/**
 * @param activeItemId
 * @param customItems
 * @param items
 * @param PLAY_AGAIN
 * @param NEXT
 * @param PREVIOUS
 * @param SET_ITEM_VALUE
 * @returns {null|*}
 * @constructor
 */
const ItemForm = ({
  activeItemId,
  customItems,
  items,
  playAgain: PLAY_AGAIN,
  setNextItemAsActive: NEXT,
  setPreviousItemAsActive: PREVIOUS,
  setItemValue: SET_ITEM_VALUE,
}) => {
  if (!activeItemId) return null;

  /**
   * Get the active item, and if none exist
   * hide the module.
   */
  const ACTIVE_ITEM_INDEX = _findIndex(items, { id: activeItemId });
  const ACTIVE_ITEM = _find(items, { id: activeItemId });
  if (!ACTIVE_ITEM) return null;

  // Items with a value
  const ITEMS_WITH_VALUE = _filter(items, (item) => item.value !== null);

  // Data
  // -------------------------------------------

  const {
    id: ITEM_ID,
    description: ITEM_TITLE,
    palette: ITEM_PALETTE,
    image: IMAGE,
    value: ITEM_VALUE,
    values: {
      default: ITEM_DEFAULT_VALUE,
      increment: ITEM_INCREMENT,
      max: ITEM_MAX,
      min: ITEM_MIN,
    },
  } = ACTIVE_ITEM;

  // State
  // -------------------------------------------

  const [value, setValue] = useState(ITEM_VALUE || ITEM_DEFAULT_VALUE);
  const [submitting, setSubmitting] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(true);
  const [windowHeight, setWindowHeight] = React.useState('100vh');

  // On mount
  useEffect(() => {
    const { innerHeight: height } = window;
    setWindowHeight(height);
    Analytics.pageView({ title: 'Items' });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page.
    setValue(ITEM_VALUE || ITEM_DEFAULT_VALUE);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ITEM_ID]);

  useEffect(() => {
    return () => {
      if (inputTimeout) clearTimeout(inputTimeout);
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  // Refs
  // -------------------------------------------

  // Add a ref as a scroll reference.
  const ITEM_LIST_REF = useRef(null);

  // Handlers
  // -------------------------------------------

  /**
   * Scroll to a ref
   *
   * @param ref
   */
  const handleScrollToRef = (ref) =>
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  /**
   * Open and close the list of items.
   *
   * @returns void
   */
  const handleShowItems = () => {
    if (showItems) {
      /**
       * IF open, close the items and
       * scroll to the top of the page.
       */
      setShowItems(false);
      window.requestAnimationFrame(() => window.scrollTo({ behavior: 'smooth', top: 0 }));
    } else {
      /**
       * If closed, show the items,
       * and scroll to the item list.
       */
      setShowItems(true);
      window.requestAnimationFrame(() => handleScrollToRef(ITEM_LIST_REF));

      Analytics.event({ category: ITEM_TITLE, action: 'View_Items' });
    }
  };

  /**
   * Update the item's value, and
   * navigate to the next item.
   *
   * @returns void
   */
  const handleSubmit = () => {
    Analytics.event({ category: ITEM_TITLE, action: 'Add' });

    setSubmitting(true);

    timeout = setTimeout(() => {
      SET_ITEM_VALUE({ id: ITEM_ID, value });
      NEXT(); // Proceed to the next item.
    }, 500);

    inputTimeout = setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  // Render
  // -------------------------------------------

  const renderItems = () => {
    if (showItems) {
      return (
        <div className="l-item-list-form__container">
          <ItemListForm customItems={customItems} items={ITEMS_WITH_VALUE} />
        </div>
      );
    }

    return null;
  };

  const INDICATOR_ACTIVE = ACTIVE_ITEM_INDEX + 1; // Arrays start at 0, my dude.

  const renderRangeSlider = () => (
    <RangeSlider
      min={ITEM_MIN}
      max={ITEM_MAX}
      step={ITEM_INCREMENT}
      value={value}
      onChange={(updatedValue) => setValue(updatedValue)}
    />
  );

  const renderButtonGroup = () => (
    <div className="l-button-group l-button-group--3-column">
      <Button
        id={`BACK_ITEM_${ITEM_ID}`}
        onClick={() => {
          Analytics.event({ category: ITEM_TITLE, action: 'Back' });

          PREVIOUS();
        }}
        disabled={ACTIVE_ITEM_INDEX === 0 || submitting}
        tabIndex="-1"
        bordered
        size="small"
        variant="tertiary"
        className="c-button--icon-left"
      >
        <ChevronLeftSVG alt='chevronLeft' width={8} height={12} />
        Back
      </Button>
      <Button
        id={`ADD_ITEM_${ITEM_ID}`}
        className="c-button--icon-right"
        onClick={() => handleSubmit()}
        disabled={submitting}
        size="large"
      >
        {ITEM_VALUE ? 'Save' : 'Add'}
        {submitting ? <CheckSVG alt='check' width={24} height={20} /> : <PlusSVG alt='plus' width={24} height={20} />}
      </Button>
      <Button
        id={`SKIP_ITEM_${ITEM_ID}`}
        onClick={() => {
          Analytics.event({ category: ITEM_TITLE, action: 'Skip' });

          NEXT();
        }}
        disabled={submitting}
        tabIndex="-1"
        bordered
        size="small"
        variant="tertiary"
        className="c-button--icon-right"
      >
        Skip
        <ChevronRightSVG alt='chevronRight' width={8} height={12} />
      </Button>
    </div>
  );

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
        }}
        contentLabel="Heads up!"
        className="c-modal"
        overlayClassName="c-modal__overlay"
      >
        <div className="c-modal__body">
          <Animation variant="fadeInUp">
            <Box>
              <Title>Heads up!</Title>
              <Text>
                To get a more accurate Tally value, enter the amount it would cost to replace your
                stuff with brand new items. Most stuff is covered for the cost of buying a brand new
                replacement e.g. a new laptop, whereas some stuff is covered for its present value
                based on age and condition e.g. clothing and footwear. For full details on what
                items are covered for replacement value vs present value, visit State.co.nz.
              </Text>
              <p className="u-mt--md">
                <button
                  id="DISCLAIMER_AGREEMENT"
                  onClick={() => {
                    Analytics.event({ action: 'Heads_Up', category: 'GotIt' });
                    setModalIsOpen(false);
                  }}
                  className="c-button c-button--icon-right c-button--secondary c-button--large c-button--block"
                  type="button"
                >
                  <span className="c-button__content">
                    Got it
                    <ChevronRightSVG alt='chevronRight' width={8} height={12} />
                  </span>
                </button>
              </p>
            </Box>
          </Animation>
        </div>
      </Modal>
      <CSSTransition key={ITEM_ID} appear in timeout={800} classNames="l-item-page--animation">
        <div
          className={`l-item-page l-item-page--${ITEM_PALETTE} ${
            showItems ? '' : 'l-item-page--fixed'
          }`}
          style={{ minHeight: windowHeight }}
        >
          <div className="l-item-page__header">
            <div className="l-item-page__indicator">
              <Indicator steps={items.length} active={INDICATOR_ACTIVE} />
            </div>
            <div className="l-item-page__nav">
              <StateLogoSVG alt='stateLogo'
                className="u-d--none u-d--md-block"
                width={143}
                height={62}
                onClick={() => PLAY_AGAIN()}
              />
              <div className="u-d--none u-d--md-block">
                <TextButton
                  id="VIEW_ITEM_LIST"
                  onClick={() => handleShowItems()}
                  disabled={!ITEMS_WITH_VALUE.length && !customItems.length}
                >
                  View my items
                </TextButton>
              </div>
            </div>
          </div>
          <div className="l-item-page__title">
            <Title level={2} className="u-text--center">
              {ITEM_TITLE}
            </Title>
          </div>
          {IMAGE ? (
            <div className="l-item-page__media">
              <img
                draggable="false"
                className="l-item-page__media-item"
                src={IMAGE.url}
                alt={IMAGE.alt}
              />
            </div>
          ) : null}
          <div className="l-item-page__sub-title">
            <Title level={2} className="u-text--center">
              {ITEM_TITLE}
            </Title>
          </div>
          <div className="l-item-page__footer">
            <div className="l-item-page__footer-header">
              {ACTIVE_ITEM_INDEX === 0 && !modalIsOpen ? (
                <Tooltip tipText="Average value" showDelay={200} showDuration={4000}>
                  {renderRangeSlider()}
                </Tooltip>
              ) : (
                renderRangeSlider()
              )}

              <div className="u-mt--md u-mt--md-lg">
                {ACTIVE_ITEM_INDEX === 0 && !modalIsOpen ? (
                  <Tooltip tipText="Add your stuff here" showDelay={1400} showDuration={4000}>
                    {renderButtonGroup()}
                  </Tooltip>
                ) : (
                  renderButtonGroup()
                )}
              </div>
            </div>
            <div className="l-item-page__footer-footer">
              <div className="u-d--md-none u-text--center">
                <TextButton
                  id="TOGGLE_ITEM_LIST"
                  onClick={() => handleShowItems()}
                  disabled={!ITEMS_WITH_VALUE.length && !customItems.length}
                  size="large"
                >
                  {showItems ? 'Hide my items ' : 'View my items '}
                  {showItems ? (
                    <ChevronUpSVG alt='chevronUp' width={12} height={8} />
                  ) : (
                    <ChevronDownSVG alt='chevronDown' width={10} height={6} />
                  )}
                </TextButton>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <div ref={ITEM_LIST_REF} className="u-mt--0">
        {renderItems()}
      </div>
    </div>
  );
};

ItemForm.defaultProps = {
  activeItemId: null,
  customItems: [],
  items: [],
};

ItemForm.propTypes = {
  activeItemId: PropTypes.number,
  customItems: PropTypes.arrayOf(PropTypes.shape({})),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  playAgain: PropTypes.func.isRequired,
  setNextItemAsActive: PropTypes.func.isRequired,
  setPreviousItemAsActive: PropTypes.func.isRequired,
  setItemValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeItemId: state.items.activeItemId,
    customItems: state.items.customItems,
    items: state.items.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  playAgain: () => dispatch(playAgain()),
  setNextItemAsActive: () => dispatch(setNextItemAsActive()),
  setPreviousItemAsActive: () => dispatch(setPreviousItemAsActive()),
  setItemValue: (state) => dispatch(setItemValue(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);
