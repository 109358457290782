import AppView from './javascript/views/app/AppView';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import configureStore from './javascript/store/Store';
import ReactGA from "react-ga4";
import {  BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import PageNotFound from './javascript/modules/PageNotFound';

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
function App() {
  const { store, persistor } = configureStore();
  return (
      
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router><Routes>
            <Route path='/' element={<AppView></AppView>}/>
            <Route path='*' element={<PageNotFound/>}/>
          </Routes></Router>
          
        </PersistGate>
      </Provider>
  );
}  

export default App;