import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param children
 * @param className
 * @param size
 * @returns {*}
 * @constructor
 */
const Highlight = ({ children, className, size }) => {
  const CLASS_NAMES = ClassNames(
    'c-highlight',
    {
      [`c-highlight--${size}`]: size,
    },
    className
  );

  return (
    <div className={CLASS_NAMES}>
      <span className="c-highlight__content">{children}</span>
    </div>
  );
};

Highlight.defaultProps = {
  className: '',
  children: null,
  size: 'large',
};

Highlight.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Highlight;
