import React from 'react'


const CheckSvg = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20.03" width={width} height={height} className={className}>
    <path fill="currentColor" d="M9.71 20L.68 13a1.77 1.77 0 012.19-2.79l6.28 4.92L20.84.66A1.78 1.78 0 0123.6 2.9z"/>
</svg>

  )
}

export default CheckSvg;