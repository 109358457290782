import { v4 as uuidv4 } from 'uuid';

import {
  ADD_CUSTOM_ITEM,
  REMOVE_CUSTOM_ITEM,
  RESET_ITEMS,
  SET_ACTIVE_ITEM_ID,
  SET_COMPLETED,
  SET_CUSTOM_ITEM_VALUE,
  SET_ITEM_VALUE,
  SET_LOADING,
  SET_SHOW_DESCRIPTION,
  SET_STARTED,
  UPDATE_ITEMS,
  UPDATE_ITEMS_FAILURE,
  UPDATE_ITEMS_SUCCESS,
} from '../constants/items';

/* eslint-disable prefer-object-spread */

const url = new URL(window.location);
const SKIP_SPLASH = url.searchParams.get('skip');

export const INITIAL_STATE = {
  activeItemId: null,
  error: null,
  items: [],
  customItems: [],
  loading: true,
  started: !!SKIP_SPLASH,
  showDescription: false,
  completed: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CUSTOM_ITEM:
      return Object.assign({}, state, {
        customItems: [
          ...state.customItems,
          {
            ...action.payload,
            id: uuidv4(),
          },
        ],
      });
    case REMOVE_CUSTOM_ITEM:
      return Object.assign({}, state, {
        customItems: state.customItems.filter((item) => item.id !== action.payload),
      });
    case SET_ACTIVE_ITEM_ID:
      return Object.assign({}, state, {
        activeItemId: action.payload,
      });
    case SET_COMPLETED:
      return Object.assign({}, state, {
        completed: true,
      });
    case SET_SHOW_DESCRIPTION:
      return Object.assign({}, state, {
        showDescription: true,
      });
    case SET_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });
    case SET_STARTED:
      return Object.assign({}, state, {
        started: true,
      });
    case SET_ITEM_VALUE:
      return Object.assign({}, state, {
        items: state.items.map((item) =>
          item.id === action.payload.id ? { ...item, value: action.payload.value } : item
        ),
      });
    case SET_CUSTOM_ITEM_VALUE:
      return Object.assign({}, state, {
        customItems: state.customItems.map((item) =>
          item.id === action.payload.id ? { ...item, value: action.payload.value } : item
        ),
      });
    case UPDATE_ITEMS:
      return Object.assign({}, state, {
        items: action.payload,
      });
    case UPDATE_ITEMS_FAILURE:
      return Object.assign({}, state, {
        error: action.payload.error,
        loading: false,
      });
    case UPDATE_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        loading: false,
      });
    case RESET_ITEMS:
      return Object.assign({}, state, {
        ...INITIAL_STATE,
        started: false,
      });
    default:
      return state;
  }
};
